import { errorMessageAPI } from 'helpers/api/messageUtils';
import { API } from 'helpers/api/server';

type getUnicodeProcessosType = {
    uniproc_codigo: number;
    uniproc_situacao?: string;
};

export async function getUnicodeProcessos(json: getUnicodeProcessosType) {
    try {
        const { data } = await API.put('TWSUnicode_Processos/GetPorCampos', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getUnicodeProcessos - ' + error });
        return [];
    }
}
