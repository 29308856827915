import axios from 'axios';
import { transformResponse } from 'helpers/json';
import { ENTERPRISE } from './enterpriseConst';

const TIMEOUT_WITHOUT: number = 0; //  Sem TimeOut
const TIMEOUT_LONG: number = 180000; //  3 minutos
const TIMEOUT_SHORT: number = 60000; // 60 Segundos

const APILogin = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: TIMEOUT_LONG,
});

const APILongTime = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: TIMEOUT_LONG,
});

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: TIMEOUT_SHORT,
});

const APIUNCOficial = axios.create({
    baseURL: process.env.REACT_APP_API_URL_UNICODEOFICIAL,
    timeout: TIMEOUT_SHORT,
});

const R2D2 = axios.create({
    //trocar para algum tipo de const no futuro?
    baseURL: 'https://r2-d2.azurewebsites.net/',
    timeout: TIMEOUT_SHORT,
});

function setAPIdefaultHeaders() {
    //Utilizado para Requisição na Base do Cliente, Utiliza todas os Headers
    API.defaults.headers['PubUsuario'] = ENTERPRISE.pubUsuario();
    API.defaults.headers['PubEmpresa'] = ENTERPRISE.pubEmpresa(); // esse 001...
    API.defaults.headers['PubEmpresaRazao'] = ENTERPRISE.pubEmpresaRazao(); // esse 001...
    API.defaults.headers['PubCNPJReg'] = ENTERPRISE.pubCNPJReg();
    API.defaults.headers['PubUsuarioAlterarEmpresa'] = ENTERPRISE.pubUsuarioAlterarEmpresa();
    API.defaults.headers['PubUsuarioVisualizarEmpLog'] = ENTERPRISE.pubUsuarioVisualizarEmpLog(); // esse S ou N
    API.defaults.headers['PubSistema'] = ENTERPRISE.pubSistema();
    API.defaults.headers['PubSistemaDescricao'] = ENTERPRISE.pubSistemaDescricao();
    API.defaults.headers['PubUpperCase'] = ENTERPRISE.pubUpperCase();
    API.defaults.headers['PubClienteRegistro'] = ENTERPRISE.pubClienteRegistro();
    API.defaults.headers['PubMacAddress'] = ENTERPRISE.pubMacAddress();
    API.defaults.headers['PubUsuarioEmail'] = ENTERPRISE.pubUsuarioEmail();
    API.defaults.headers['PubTipoSistema'] = ENTERPRISE.pubTipoSistema();
    API.defaults.headers['PubModoLogin'] = ENTERPRISE.pubModoLogin();
    API.defaults.transformResponse = [transformResponse];
    API.defaults.auth = {
        username: 'MASTER',
        password: 'MASTER',
    };

    APILongTime.defaults.headers['PubUsuario'] = ENTERPRISE.pubUsuario();
    APILongTime.defaults.headers['PubEmpresa'] = ENTERPRISE.pubEmpresa(); // esse 001...
    APILongTime.defaults.headers['PubEmpresaRazao'] = ENTERPRISE.pubEmpresaRazao(); // esse 001...
    APILongTime.defaults.headers['PubCNPJReg'] = ENTERPRISE.pubCNPJReg();
    APILongTime.defaults.headers['PubUsuarioAlterarEmpresa'] = ENTERPRISE.pubUsuarioAlterarEmpresa();
    APILongTime.defaults.headers['PubUsuarioVisualizarEmpLog'] = ENTERPRISE.pubUsuarioVisualizarEmpLog(); // esse S ou N
    APILongTime.defaults.headers['PubSistema'] = ENTERPRISE.pubSistema();
    APILongTime.defaults.headers['PubSistemaDescricao'] = ENTERPRISE.pubSistemaDescricao();
    APILongTime.defaults.headers['PubUpperCase'] = ENTERPRISE.pubUpperCase();
    APILongTime.defaults.headers['PubClienteRegistro'] = ENTERPRISE.pubClienteRegistro();
    APILongTime.defaults.headers['PubMacAddress'] = ENTERPRISE.pubMacAddress();
    APILongTime.defaults.headers['PubUsuarioEmail'] = ENTERPRISE.pubUsuarioEmail();
    APILongTime.defaults.headers['PubTipoSistema'] = ENTERPRISE.pubTipoSistema();
    APILongTime.defaults.headers['PubModoLogin'] = ENTERPRISE.pubModoLogin();
    APILongTime.defaults.transformResponse = [transformResponse];
    APILongTime.defaults.auth = {
        username: 'MASTER',
        password: 'MASTER',
    };

    //Utilizado para Requisição na Base da UNC/Base de Cliente, Utiliza configuração de Login/Usuario
    APILogin.defaults.headers['PubSistema'] = ENTERPRISE.pubSistema();
    APILogin.defaults.headers['PubSistemaDescricao'] = ENTERPRISE.pubSistemaDescricao();
    APILogin.defaults.headers['PubMacAddress'] = ENTERPRISE.pubMacAddress();
    APILogin.defaults.headers['PubTipoSistema'] = ENTERPRISE.pubTipoSistema();
    APILogin.defaults.headers['PubModoLogin'] = ENTERPRISE.pubModoLogin();
    APILogin.defaults.transformResponse = [transformResponse];
    APILogin.defaults.auth = {
        username: 'MASTER',
        password: 'MASTER',
    };

    //Utilizado para Requisição na Base da UNC / Banco Especifico da UNC
    APIUNCOficial.defaults.headers['PubSistema'] = ENTERPRISE.pubSistema();
    APIUNCOficial.defaults.headers['PubSistemaDescricao'] = ENTERPRISE.pubSistemaDescricao();
    APIUNCOficial.defaults.headers['PubMacAddress'] = ENTERPRISE.pubMacAddress();
    APIUNCOficial.defaults.headers['PubTipoSistema'] = ENTERPRISE.pubTipoSistema();
    APIUNCOficial.defaults.headers['PubModoLogin'] = ENTERPRISE.pubModoLogin();
    APIUNCOficial.defaults.transformResponse = [transformResponse];
    APIUNCOficial.defaults.auth = {
        username: 'MASTER',
        password: 'MASTER',
    };

    R2D2.defaults.headers['Authorization'] =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Ik1BU1RFUiIsImlhdCI6MTcwMTQyNjYzM30.fWXvvl1tKL4TkJXXewYzdszj0HUkHYb74TgaEqEJmKs';
}

setAPIdefaultHeaders();

export { API, APILogin, APILongTime, R2D2, APIUNCOficial, TIMEOUT_WITHOUT, setAPIdefaultHeaders };
