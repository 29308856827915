import { SideBarTheme } from 'appConstants';
import { entrar } from 'helpers/loginController';
import { useUser } from 'hooks';
import { ENTERPRISE } from './enterpriseConst';
import { colorUtilsUNCType } from './uncUtils';
import { errorMessageAPI } from './messageUtils';
import { jsonWithStructure } from 'helpers/json';
import { API, R2D2 } from './server';

type DownloadFile = {
    data: any;
    filename: string;
    mime: any;
    bom: any;
};

const downloadFile = ({ data, filename, mime, bom }: DownloadFile) => {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    var blobURL =
        window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blob)
            : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 200);
};

export { downloadFile };

export function RetornaCorTipoSistema(): colorUtilsUNCType {
    var produto = ENTERPRISE.pubProduto();
    var tiposistema = ENTERPRISE.pubTipoSistema();
    var color: colorUtilsUNCType = '';

    if (produto === 'GESTAO') {
        if (tiposistema === '0') color = 'empresarial';
        else if (tiposistema === '1') {
            color = 'tectum';
        } else if (tiposistema === '2') {
            color = 'split';
        } else color = 'empresarial';
    } else if (produto === 'CRM') {
        color = 'industrialmais';
    }

    return color;
}
type systemUtilsUNCType = 'empresarial' | 'tectum' | 'split';

export function RetornaTipoSistema(): colorUtilsUNCType {
    var tiposistema = ENTERPRISE.pubTipoSistema();
    var system: systemUtilsUNCType = 'empresarial';

    if (tiposistema === '0') system = 'empresarial';
    else if (tiposistema === '1') {
        system = 'tectum';
    } else if (tiposistema === '2') {
        system = 'split';
    } else system = 'empresarial';
    return system;
}

function RetornaLayoutSistema() {
    var tectum = SideBarTheme.LEFT_SIDEBAR_THEME_TECTUM;
    var split = SideBarTheme.LEFT_SIDEBAR_THEME_SPLIT;
    var empresarial = SideBarTheme.LEFT_SIDEBAR_THEME_EMPRESARIAL;
    var industrialmais = SideBarTheme.LEFT_SIDEBAR_THEME_INDUSTRIALMAIS;
    var tiposistema = RetornaCorTipoSistema();

    if (tiposistema === 'tectum') {
        return tectum;
    } else if (tiposistema === 'split') {
        return split;
    } else if (tiposistema === 'empresarial') {
        return empresarial;
    } else if (tiposistema === 'industrialmais') {
        return industrialmais;
    }
}
export { RetornaLayoutSistema };

function limpaObjetos(objetoLimpar: Array<any>) {
    while (objetoLimpar?.length > 0) {
        objetoLimpar.pop();
    }
}

function existeString(n: string): boolean {
    if (n === '' ?? n === undefined ?? !n) {
        return false;
    } else {
        return true;
    }
}

type EnvioEmailR2d2Type = {
    emailUnc: boolean;
    req: {
        email: string;
        email_cc?: string;
        email_cco?: string;
        assunto: string;
        corpo_email?: string;
        corpo_emailHTML?: string;
        conta?: string;
        conta_envio?: string;
        conta_enviosenha?: string;
        conta_enviolink?: string;
        conta_envioporta?: string;
    };
};
export async function envioEmailR2d2(props: EnvioEmailR2d2Type) {
    let jsonEnvio = {};
    if (props.emailUnc) {
        jsonEnvio = { ...props.req, produto_envio: ENTERPRISE.pubProduto() };
    } else {
        jsonEnvio = props.req;
    }

    try {
        const { data } = await R2D2.put(`/EnviarEmail`, jsonEnvio);
        return data;
    } catch (error) {
        errorMessageAPI({
            text: 'Não foi possível realizar o envio de email, verifique suas credênciais.',
        });
    }
}

export { limpaObjetos, existeString };
