import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import {
    ENTERPRISE,
    setEmpresaPadrao,
    setErrorRenderPage,
    setPubClienteRegistro,
    setPubCNPJReg,
    setPubEmpresa,
    setPubEmpresaRazao,
    setPubMacAddress,
    setPubSistema,
    setPubSistemaDescricao,
    setPubTipoSistema,
    setPubUpperCase,
    setPubUsuario,
    setPubUsuarioAlterarEmpresa,
    setPubUsuarioEmail,
    setPubUsuarioVisualizarEmpLog,
    setUsuarioLogado,
} from './api/enterpriseConst';
import { API, setAPIdefaultHeaders } from './api/server';
import { jsonWithStructure } from './json';
import { APIresponseUNC } from 'webservice/TYPES_WEBSERVICE/types';
import { getPendingContract } from 'webservice/sistema';
import { alertMessage, timerAutoClose } from './api/messageUtils';
import { logout } from './api';
import { CODE_PENDINGCONTRACT } from './api/blockCodeUtils';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

function entrar() {
    mock.onPost('/TWSLogin/LoginEmail').reply(function (config) {
        return new Promise(async function (resolve, reject) {
            let params = JSON.parse(config.data);
            const dadosUsuario = JSON.parse(ENTERPRISE.pubUsuarioLogado());

            var json = jsonWithStructure({
                usuario_emaillogin: params.usuario_emaillogin ? params.usuario_emaillogin : dadosUsuario.email,
                login_macadress: 'WEBAPP',
                usuario_senha: params.usuario_senha ? params.usuario_senha : dadosUsuario.senha,
            });
            setErrorRenderPage({ code: '', message: '' });
            setPubUsuario('');
            setPubEmpresa('');
            setPubCNPJReg('');
            setPubUsuarioAlterarEmpresa('');
            setPubUsuarioVisualizarEmpLog('');

            if (ENTERPRISE.pubProduto() === 'GESTAO') {
                setPubSistema('0002');
                setPubSistemaDescricao('UNC - App Enterprise');
            } else {
                setPubSistema('0003');
                setPubSistemaDescricao('CRM - IndustrialMais');
            }

            setPubUpperCase(true);
            setPubClienteRegistro('');
            setPubMacAddress('WEBAPP');
            setPubUsuarioEmail(params.usuario_emaillogin);
            setPubTipoSistema(process.env.REACT_APP_TIPO_SITEMA_DEFAULT);

            setAPIdefaultHeaders();

            API.defaults.headers['PubUsuarioEmail'] = params.usuario_emaillogin;

            try {
                const { data } = await API.put('/TWSLogin/LoginEmail', json);
                const { getFirstData, getFirstMessage, isSuccessful } = data;
                if (isSuccessful) {
                    setPubUsuario(getFirstData().usuario_login);
                    setPubEmpresa(getFirstData().empresa_codigo);
                    setPubEmpresaRazao(getFirstData().empresa_razao);
                    setEmpresaPadrao(
                        getFirstData().empresa_codigo,
                        getFirstData().empresa_razao,
                        getFirstData().empresa_cnpj
                    );
                    setPubCNPJReg(getFirstData().pubcnpjreg);
                    setPubUsuarioAlterarEmpresa(getFirstData().usuario_permitealterarempresa);
                    setPubUsuarioVisualizarEmpLog(getFirstData().usuario_visualizarempresa);

                    if (ENTERPRISE.pubProduto() === 'GESTAO') {
                        setPubSistema('0002');
                        setPubSistemaDescricao('UNC - App Enterprise');
                    } else {
                        setPubSistema('0003');
                        setPubSistemaDescricao('CRM - IndustrialMais');
                    }

                    setPubUpperCase(true);
                    // FALAR COM O CARRIGO, CLIENTE_CODIGO TA VINDO VAZIO
                    setPubClienteRegistro(getFirstData().cliente_codigo);
                    setPubMacAddress('WEBAPP');
                    setPubUsuarioEmail(getFirstData().usuario_emaillogin);
                    setPubTipoSistema(getFirstData().clienteregistro_tiposistema);

                    setUsuarioLogado(
                        getFirstData().usuario_login,
                        getFirstData().usuario_emaillogin,
                        params.usuario_senha,
                        getFirstData().usuario_nome
                    );

                    setAPIdefaultHeaders();

                    resolve([200, getFirstData()]);
                } else {
                    const msg = getFirstMessage();
                    resolve([401, { message: msg }]);
                }
            } catch (e) {
                const msg = `${e.message} - a API não respondeu`;
                resolve([1, { message: msg }]);
            }
        });
    });
}

export { entrar };
