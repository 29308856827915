import { colorUtilsUNC, iconUtilsUNC } from './uncUtils';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import toastr from 'toastr';
import { stringToNumber } from './numberUtils';
import { COLOR } from 'rsuite/esm/utils/constants';

// MONTA TOAST VISUAL
// https://codeseven.github.io/toastr/demo.html

interface confirmationMessageProps {
    title: string;
    text: string;
    icon: 'success' | 'error' | 'warning' | 'info' | 'question';
    confirmButtonText: string;
    cancelButtonText?: string;
    reverseButtons?: boolean;
    invertButtonsColor?: boolean;
    showCancelButton?: boolean;
    showDenyButton?: boolean;
    denyButtonText?: string;
    focusConfirm?: boolean;
}

interface alertMessageProps {
    title: string;
    text: string;
    icon: 'success' | 'error' | 'warning' | 'info' | 'question';
    footer?: string;
}

interface sucessMessageProps {
    title: string;
    text: string;
}
interface errorMessageProps {
    text: string;
}

interface infoMessageProps {
    title: string;
    text: string;
}

interface alertToastType {
    message: string;
    title: string;
    showDuration: string;
    progressBar: boolean;
}

interface messageLogsAPItype {
    result: any;
    successMessage?: string;
    reloadData?: Function;
    showOnlyFirst?: boolean;
}

export function confirmationMessage(props: confirmationMessageProps) {
    const swalConfirmationMessage = Swal.mixin({
        customClass: {
            confirmButton: props?.invertButtonsColor ? 'btn  m-1' : 'btn btn-success m-1',
            cancelButton: props?.invertButtonsColor ? 'btn btn-success m-1' : 'btn btn-danger m-1',
            icon: 'fs-6',
        },
        buttonsStyling: false,
    });

    const response = swalConfirmationMessage.fire({
        title: props.title,
        text: props.text,
        icon: props.icon,
        showCancelButton: props?.showCancelButton !== undefined ? props.showCancelButton : true,
        confirmButtonText: props.confirmButtonText,
        cancelButtonText: props?.cancelButtonText,
        reverseButtons: props.reverseButtons ? props.reverseButtons : false,
        showDenyButton: props?.showDenyButton !== undefined ? props.showDenyButton : false,
        denyButtonText: props?.denyButtonText,
        focusConfirm: props?.focusConfirm,
    });

    return response;
}

export function alertMessage(props: alertMessageProps) {
    const response = Swal.fire({
        icon: props.icon,
        title: props.title,
        text: props.text,
        footer: props?.footer,
        focusConfirm: false,
        customClass: { icon: 'fs-6', confirmButton: 'bg-' + props.icon },
    });

    return response;
}

export function errorMessageAPI(props: errorMessageProps) {
    const response = Swal.fire({
        icon: 'error',
        title: 'Opsss... Algo inesperado aconteceu!',
        html: 'Tente realizar novamente a operação. <br><br>' + props.text,
        customClass: { icon: 'fs-6' },
        didOpen: () => {
            if (Swal.isLoading()) {
                Swal.hideLoading();
            }
        },
    });

    return response;
}

export function infoMessageAPI(props: infoMessageProps) {
    const response = Swal.fire({
        icon: 'info',
        title: props.title,
        html: props.text,
        customClass: { icon: 'fs-6' },
    });

    return response;
}
export function warningMessageAPI(props: errorMessageProps) {
    const response = Swal.fire({
        icon: 'warning',
        title: 'Opsss... Algo inesperado aconteceu!',
        html: props.text,
    });

    return response;
}

export function successMessageAPI(props: sucessMessageProps) {
    const response = Swal.fire({
        icon: 'success',
        title: props.title,
        html: props.text,
    });

    return response;
}

export function showToast(props, type) {
    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: props?.progressBar,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: props?.showDuration,
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
    };
    if (type === 'error') {
        toastr.error(props?.message, props?.title);
    } else if (type === 'success') {
        toastr.success(props?.message, props?.title);
    } else if (type === 'warning') {
        toastr.warning(props?.message, props?.title);
    }
}

export function alertToast(props: alertToastType) {
    showToast(props, 'success');
}

export function warningToast(props: alertToastType) {
    showToast(props, 'warning');
}

export function errorToast(props: alertToastType) {
    showToast(props, 'error');
}

export function messageLogsAPI(props: messageLogsAPItype) {
    if (props.result.result === '1' && !props.showOnlyFirst) {
        errorMessageAPI({
            text: props.result.messages.map((message) => {
                return '<b>' + message.message + '</b>' + '<br><br>' + message.help;
            }),
        });
    }

    if (props.showOnlyFirst && props.result.result === '1') {
        errorMessageAPI({
            text: '<b>' + props.result.messages[0].message + '</b>' + '<br><br>' + props.result.messages[0].help,
        });
    }

    if (props.result.result === '0') {
        if (props?.result?.messagesSuccess?.length > 0) {
            successMessageAPI({
                title: 'Négocio ganho com Sucesso!',
                text: props?.result?.messagesSuccess?.map((message) => {
                    return '<b>' + message?.message + '</b>' + '<br>' + message.help;
                }),
            });
        }

        if (props.reloadData) {
            props.reloadData();
        }
    }

    if (props.successMessage) {
        if (props.result.result === '0') {
            alertToast({
                message: props.successMessage,
                title: 'Sucesso',
                showDuration: '3000',
                progressBar: true,
            });
        }
    }
}

export async function customMessage(props: SweetAlertOptions<any, any>) {
    const response = await Swal.fire(props);
    return response;
}

export async function messageTextArea(props: SweetAlertOptions<any, any>) {
    const dataProps = {
        title: props?.title,
        input: props.input,
        inputValue: props.inputValue,
        inputLabel: props.inputLabel,
        inputPlaceholder: props.inputPlaceholder,
        inputAttributes: {
            'aria-label': props.inputAttributes['aria-label'],
            required: props.inputAttributes.required,
        },
        validationMessage: props.validationMessage,
        showCancelButton: props.showCancelButton,
        cancelButtonText: `<i class="${iconUtilsUNC('close')}"></i>`,
        confirmButtonText: `<i class="${iconUtilsUNC('save')}"></i> Salvar`,
        confirmButtonColor: colorUtilsUNC('green'),
        cancelButtonColor: colorUtilsUNC('gray'),
    };
    const response = await Swal.fire(dataProps);
    return response;
}

export async function confirmRadioButton() {
    const response = await Swal.fire({
        title: 'Finalizar Tarefa',
        html: `
            <div>
                <label>Objetivo Concluído?</label>
            <div class="mt-1">
                <input type="radio" name="check" id="radioYes" value="true" />
                <label for="radioYes">Sim</label>
                <input type="radio" name="check" id="radioNo" value="false" />
                <label for="radioNo">Não</label>
            </div>
                <textarea id="descricao" class="form-control mt-3" placeholder="Digite sua mensagem... (opcional)" rows="4"></textarea>
            </div>
        `,
        showCancelButton: true,
        focusConfirm: false,
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
            const check = document.querySelector('input[name="check"]:checked')?.['value'];
            const descricao = (document.querySelector('#descricao') as HTMLTextAreaElement)?.value;

            if (!descricao) {
                Swal.showValidationMessage('Selecione uma opção');
                return false;
            }

            return { check, descricao };
        },
    });

    return response;
}

export async function editDueDate() {
    const response = await Swal.fire({
        title: 'Editar Dt. Vencimento',
        html: `<input id="datetime-input" type="date"  class="form-control" placeholder="Data" style="margin-bottom: 15">`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
            const date = (document.querySelector('#datetime-input') as HTMLTextAreaElement)?.value;
            if (!date) {
                Swal.showValidationMessage('Preencha a nova Dt. de Vencimento');
                return false;
            }

            return { date };
        },
    });

    return response;
}

export async function editValue() {
    const response = await Swal.fire({
        title: 'Editar Valor',
        html: `<input id="value-input" type="text" class="form-control" placeholder="Valor" style="margin-bottom: 15">`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        didOpen: () => {
            const input = Swal.getHtmlContainer()?.querySelector('#value-input') as HTMLInputElement;
            input.focus();
            input.addEventListener('input', (event) => {
                const target = event.target as HTMLInputElement;
                let value = target.value.replace(/\D/g, '');
                value = value.replace(/(\d)(\d{2})$/, '$1,$2');
                value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
                target.value = value;
            });
        },
        preConfirm: () => {
            const value: number = Number((document.querySelector('#value-input') as HTMLTextAreaElement)?.value);
            if (!value && value <= 0) {
                Swal.showValidationMessage('Preencha o valor');
                return false;
            }

            return { value };
        },
    });
    return response;
}

type alertDateTimeLocalType = {
    valueDateTime: string;
    valueTextArea: string;
};
export async function alertDateTimeLocal(props: alertDateTimeLocalType) {
    const response = await Swal.fire({
        title: 'Reagendar',
        html: `<input id="datetime-input" type="datetime-local" value="${props.valueDateTime}" class="form-control" placeholder="Data" style="margin-bottom: 15"> 
            <textarea id="textarea-input" type="textarea" class="form-control" size="100" placeholder="Digite o motivo do reagendamento..." rows="4" cols="50"> ${props.valueTextArea} </textarea>`,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
            const date = (document.querySelector('#datetime-input') as HTMLTextAreaElement)?.value;
            const text = (document.querySelector('#textarea-input') as HTMLTextAreaElement)?.value;
            if (!date) {
                Swal.showValidationMessage('Preencha a data e horário de reagendamento');
                return false;
            } else if (new Date(date) < new Date()) {
                Swal.showValidationMessage('A data escolhida não pode ser menor que a data atual');
            }
            if (!text) {
                Swal.showValidationMessage('Preencha o motivo do reagendamento');
                return false;
            }

            return { date, text };
        },
    });

    return response;
}

export async function newRecurrence(abrirnovanegociacao: boolean, obrigaposvenda: boolean) {
    const response = await Swal.fire({
        title: 'O que vamos fazer?',
        html: `
          <div>
            <input class="form-check-input" type="checkbox" id="posVendaCheckbox" ${
                obrigaposvenda ? 'checked disabled' : ''
            }  />
            <label class="form-check-label">
                Enviar negócio para pós-venda
            </label>
          </div>
          <div>
            <input class="form-check-input mt-2" type="checkbox" ${
                abrirnovanegociacao ? 'checked disabled' : ''
            }  id="novoNegocioCheckbox" />
            <label class="form-check-label mt-2">
                Abrir novo negócio recorrente
            </label>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            const posVendaCheckbox = document.getElementById('posVendaCheckbox') as HTMLInputElement;
            const novoNegocioCheckbox = document.getElementById('novoNegocioCheckbox') as HTMLInputElement;

            // Lógica para recuperar os valores dos checkboxes
            const posVendaSelecionado = posVendaCheckbox.checked;
            const novoNegocioSelecionado = novoNegocioCheckbox.checked;

            // Lógica para processar os valores selecionados

            return { posVendaSelecionado, novoNegocioSelecionado }; // Pode retornar um valor qualquer, ou uma Promise
        },
    });
    return response;
}

export async function winAfterSalesConfirmation(abrirnovanegociacao: boolean) {
    const response = await Swal.fire({
        title: 'O que vamos fazer?',
        html: `          
          <div>
            <input class="form-check-input mt-2" type="checkbox" ${
                abrirnovanegociacao ? 'checked disabled' : ''
            }  id="novoNegocioCheckbox" />
            <label class="form-check-label mt-2">
                Abrir novo negócio recorrente
            </label>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Finalizar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            const novoNegocioCheckbox = document.getElementById('novoNegocioCheckbox') as HTMLInputElement;

            // Lógica para recuperar os valores dos checkboxes
            const novoNegocioSelecionado = novoNegocioCheckbox.checked;

            // Lógica para processar os valores selecionados

            return { novoNegocioSelecionado }; // Pode retornar um valor qualquer, ou uma Promise
        },
    });
    return response;
}
export async function copyBusinessConfirmation() {
    const response = await Swal.fire({
        title: 'O que vamos fazer?',
        html: `
         <div>
             <label class="form-check-label">
                Copiar negócio para daqui 
            </label>
            <input  type="number" style="width: 50px" value="0" id="qtde_dias" />
            <label class="form-check-label">
                Dias
            </label>
          </div>
          <div>
            <input class="form-check-input" type="checkbox" checked id="copiarTarefasPendentesCheckbox" />
            <label class="form-check-label">
                Copiar Tarefas Pendentes
            </label>
          </div>
          <div>
            <input class="form-check-input mt-2" type="checkbox"  checked id="copiarVendaEOrcamentoCheckbox" />
            <label class="form-check-label mt-2">
                Copiar Venda e Gerar Orçamento
            </label>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            const copiarTarefasCheckbox = document.getElementById('copiarTarefasPendentesCheckbox') as HTMLInputElement;
            const copiarVendaeOrcamentoCheckbox = document.getElementById(
                'copiarVendaEOrcamentoCheckbox'
            ) as HTMLInputElement;
            const qtde_dias = document.getElementById('qtde_dias') as HTMLInputElement;

            // Lógica para recuperar os valores dos checkboxes
            const copiarTarefasSelecionado = copiarTarefasCheckbox.checked;
            const copiarVendaOrcamentoSelecionado = copiarVendaeOrcamentoCheckbox.checked;
            const qtdedias_selecionada = qtde_dias.value;

            // Lógica para processar os valores selecionados
            if (stringToNumber(qtdedias_selecionada) < 0) {
                Swal.showValidationMessage('A quantidade de dias deve ser maior ou igual a 0.');
            } else {
                return { copiarTarefasSelecionado, copiarVendaOrcamentoSelecionado, qtdedias_selecionada };
            }

            // return { copiarTarefasSelecionado, copiarVendaOrcamentoSelecionado, qtdedias_selecionada }; // Pode retornar um valor qualquer, ou uma Promise
        },
    });
    return response;
}

type timerAutoCloseType = {
    title: string;
    html: string;
    timer: number;
    timerProgressBar: boolean;
};

export function timerAutoClose(props: timerAutoCloseType) {
    let timerInterval;
    let totalCount = Math.floor(props.timer / 1000);
    let currentCount = 0;

    Swal.fire({
        title: props.title,
        html: `${props.html} <br>${currentCount} de ${totalCount}`,
        timer: props.timer,
        timerProgressBar: props.timerProgressBar,
        customClass: {
            timerProgressBar: 'bg-success',
        },
        allowEscapeKey: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();
            let b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
                currentCount += 1;
                const percentage = (currentCount / totalCount) * 100;
                b.textContent = percentage.toFixed(0);
                Swal.update({
                    html: `${props.html} <br>${currentCount} de ${totalCount}`,
                });
            }, props.timer / totalCount);
        },
        willClose: () => {
            clearInterval(timerInterval);
        },
    });
}

type processingMessageType = {
    title: string;
    html: string;
    timer: number;
    timerProgressBar: boolean;
    icon: SweetAlertIcon;
};

export function processingMessage(props: processingMessageType) {
    let timerInterval;

    Swal.fire({
        icon: props.icon,
        title: props.title,
        html: `${props.html}`,
        timer: props.timer,
        timerProgressBar: props.timerProgressBar,
        didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
                // b.textContent = '';
            }, 100);
        },
        willClose: () => {
            clearInterval(timerInterval);
        },
    }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
    });
}

type htmlCustomizableType = {
    fieldName: string;
    title: string;
    html: string;
    messageEmptyField: string;
};

export async function htmlCustomizable(props: htmlCustomizableType) {
    const response = await Swal.fire({
        icon: 'error',
        title: props.title,
        html: props.html,
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Salvar',
        cancelButtonText: 'Cancelar',
        preConfirm: () => {
            const field = (document.querySelector(`#${props.fieldName}`) as HTMLTextAreaElement)?.value;
            if (!field) {
                Swal.showValidationMessage(props.messageEmptyField);
                return false;
            }

            return field;
        },
    });

    return response;
}
