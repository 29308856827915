import { APICore } from './apiCore';
import { setErrorRenderPage, setUsuarioLogado } from './enterpriseConst';

const api = new APICore();

function login(params: { usuario_emaillogin: string; usuario_senha: string }) {
    const baseUrl = '/TWSLogin/LoginEmail';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    setUsuarioLogado('', '', '', '');
    setErrorRenderPage({ code: '', message: '' });

    // const baseUrl = '/logout/';
    // return api.create(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; usuario_senha: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { usuario_emaillogin: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
