import axios from 'axios';
import { alertMessage } from 'helpers/api/messageUtils';
import { R2D2 } from 'helpers/api/server';

type getSearchCNPJProps = {
    CNPJ: string;
    showCatch?: boolean;
};

async function getSearchCNPJ(props: getSearchCNPJProps) {
    var replace = props?.CNPJ?.replace(/[./-]/g, '');
    const showCatch = props?.showCatch ?? true;

    try {
        const dataCNPJ = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${replace}`);
        return dataCNPJ;
    } catch (error) {
        if (showCatch) {
            alertMessage({ icon: 'warning', title: 'Erro ao buscar dados do CNPJ', text: error });
        }
    }
}

async function getSearchCNPJR2D2(props: getSearchCNPJProps) {
    var replace = props?.CNPJ?.replace(/[./-]/g, '');
    const showCatch = props?.showCatch ?? true;
    try {
        const dataCNPJ = await R2D2.get(`/cnpj/${replace}`);
        return dataCNPJ;
    } catch (error) {
        if (showCatch) {
            alertMessage({ icon: 'warning', title: 'Erro ao buscar dados do CNPJ', text: error });
        }
    }
}

export { getSearchCNPJ, getSearchCNPJR2D2 };
