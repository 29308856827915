import { errorMessageAPI } from 'helpers/api/messageUtils';
import { ENTERPRISE } from 'helpers/api/enterpriseConst';
import { API } from '../../helpers/api/server';
import { TModelUsuario } from 'helpers/model/usuario';
import { jsonWithStructure } from 'helpers';
import { CODE_PENDINGCONTRACT, CODE_VALIDITYEXPIRED } from 'helpers/api/blockCodeUtils';
import { developmentEnvironment } from 'helpers/api/developmentEnvironment';

async function getVerAcesso(sistema_codigo, programa_codigo, rotina_codigo) {
    try {
        const parameters = `${ENTERPRISE.pubUsuario()}/${programa_codigo}/${rotina_codigo}/${sistema_codigo}`;

        const { data } = await API.get('TWSUsuario/GetAcesso/' + parameters);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getVerAcesso - ' + error });
        return [];
    }
}

function trataRetornaVerAcesso(result, setHasPermission: Function, programa_codigo: number, rotina_codigo: number) {
    let hasPermissionErro: boolean = false;

    if (programa_codigo === 0 && rotina_codigo === 0) {
        setHasPermission({
            hasPermission: true,
            message: '',
            help: '',
        });
    } else if (result.result === '1') {
        //SE FOR DESENVOLVIMENTO
        if (developmentEnvironment) {
            hasPermissionErro = true;

            if (
                ENTERPRISE?.getErrorRenderPage()?.code === CODE_VALIDITYEXPIRED ||
                ENTERPRISE?.getErrorRenderPage()?.code === CODE_PENDINGCONTRACT
            ) {
                hasPermissionErro = false;
            }
        }
        setHasPermission({
            hasPermission: hasPermissionErro,
            message: result.messages[0].message,
            help: result.messages[0].help,
        });
    } else {
        hasPermissionErro = true;
        if (
            ENTERPRISE?.getErrorRenderPage()?.code === CODE_VALIDITYEXPIRED ||
            ENTERPRISE?.getErrorRenderPage()?.code === CODE_PENDINGCONTRACT
        ) {
            hasPermissionErro = false;
        }

        setHasPermission({
            hasPermission: hasPermissionErro,
            message: '',
            help: '',
        });
    }
}

async function verAcesso(programa_codigo: number, rotina_codigo: number, setHasPermission: Function) {
    const result = await getVerAcesso(ENTERPRISE.pubSistema(), programa_codigo, rotina_codigo);
    trataRetornaVerAcesso(result, setHasPermission, programa_codigo, rotina_codigo);
    return result;
}

async function verAcessoCRM(programa_codigo: number, rotina_codigo: number, setHasPermission: Function) {
    const result = await getVerAcesso(ENTERPRISE.CRM_SISTEMACODIGO(), programa_codigo, rotina_codigo);
    trataRetornaVerAcesso(result, setHasPermission, programa_codigo, rotina_codigo);

    return result;
}

async function verAcessoGESTAO(programa_codigo: number, rotina_codigo: number, setHasPermission: Function) {
    const result = await getVerAcesso(ENTERPRISE.GESTAO_SISTEMACODIGO(), programa_codigo, rotina_codigo);
    trataRetornaVerAcesso(result, setHasPermission, programa_codigo, rotina_codigo);
    return result;
}

type getUsuarioProps = {
    textoBusca?: string;
    selectedId?: string;
};

async function getUsuario(props: getUsuarioProps) {
    try {
        const json = {
            usuario_ativo: 'S',
            CAMPOS: ['usuario_login', 'usuario_nome', 'usuario_acessocrm'],
            SQL_FILTRO_GERAL: props.textoBusca ? props.textoBusca : '',
            ORDEM: ['usuario_nome'],
        };

        const { data } = await API.put('TWSUsuario/GetPorCampos', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getUsuario - ' + error });
        return [];
    }
}

async function getEmailUsuario(props: getUsuarioProps) {
    try {
        const json = {
            usuario_ativo: 'S',
            CAMPOS: [
                'usuario_nome',
                'usuario_smtp',
                'usuario_porta',
                'usuario_emailnome',
                'usuario_email',
                'usuario_emailcripto',
                'usuario_emailcopiaoculta',
                'usuario_emailsenha',
                'usuario_tipoemail',
            ],
            SQL_FILTRO_GERAL: props.textoBusca ? props.textoBusca : '',
            ORDEM: ['usuario_nome'],
        };

        const { data } = await API.put('TWSUsuario/GetPorCampos', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getUsuario - ' + error });
        return [];
    }
}

async function getRepresUsuario(usuario_login: string) {
    try {
        const json = { usuario_login: usuario_login };

        const { data } = await API.put('TWSUsuario/GetRepresentanteUsuario', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getRepresUsuario - ' + error });
        return [];
    }
}

type getListaUsuarioType = {
    textoBusca: any;
    exibir_somente_ativos?: 'S' | 'N';
};
async function getListaUsuario(props: getListaUsuarioType) {
    try {
        const json = {
            FILTRO_GERAL: props.textoBusca,
            USUARIO_ATIVO: props.exibir_somente_ativos ?? '',
            ORDEM: 'A.USUARIO_NOME',
        };

        const { data } = await API.put('TWSUsuario/GetLista', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getListaUsuario - ' + error });
        return [];
    }
}

type PermissoesUsuarioType = {
    grupopermissao_codigo: string;
    grupopermissao_descricao: string;
    nivel: number;
    permissao_grupo: 'S' | 'N';
    permissao_usuario: 'S' | 'N';
    programa_codigo: number;
    rotina_codigo: number;
    rotina_descricao: string;
    moduloprograma_posicao: string;
};

async function getPermissoesUsuario(userLogin: string, moduleCode: string): Promise<PermissoesUsuarioType[]> {
    try {
        const { data } = await API.put('TWSUSuario/GetListaPermissaoUsuario', {
            USUARIO_LOGIN: userLogin,
            MODULO_CODIGO: moduleCode,
        });

        return data?.content?.DADOS?.[0] ?? [];
    } catch (error) {
        errorMessageAPI({ text: 'getPermissoesUsuario - ' + error });
        return [];
    }
}

type PermissoesGrupoType = {
    nivel: number;
    permissao_grupo: 'S' | 'N';
    programa_codigo: number;
    rotina_codigo: number;
    rotina_descricao: string;
    moduloprograma_posicao: string;
};

export async function getPermissoesGrupo(
    grupousuario_codigo: string,
    moduleCode: string
): Promise<PermissoesGrupoType[]> {
    try {
        const { data } = await API.put('TWSUsuario/GetListaPermissaoGrupo_Usuario', {
            GRUPOUSUARIO_CODIGO: grupousuario_codigo,
            MODULO_CODIGO: moduleCode,
        });

        return data?.content?.DADOS?.[0] ?? [];
    } catch (error) {
        errorMessageAPI({ text: 'getPermissoesGrupo - ' + error });
        return [];
    }
}

type deletarType = {
    usuario_login: string;
};

type UserType = {
    dadosJson?: TModelUsuario;
    dadosDeletar?: deletarType;
};

async function putUser(props?: UserType) {
    try {
        const json = jsonWithStructure(props.dadosJson);
        const { data } = await API.put('/TWSUsuario/Usuario', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'putUser - ' + error });
        return [];
    }
}

async function postUser(props?: UserType) {
    try {
        const json = jsonWithStructure(props.dadosJson);
        const { data } = await API.post('/TWSUsuario/Usuario', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'postUser - ' + error });
        return [];
    }
}

async function patchUser(props?: UserType) {
    try {
        const json = jsonWithStructure(props.dadosJson);
        const { data } = await API.post('/TWSUsuario/patchUsuario', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'patchUser - ' + error });
        return [];
    }
}

async function deleteUser(props: deletarType) {
    try {
        const { data } = await API.delete(`/TWSUsuario/Usuario/${props?.usuario_login}`);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'deleteUser - ' + error });
        return [];
    }
}

async function removeAccessCRM() {
    try {
        const { data } = await API.put('/TWSUsuario/RemoveAcessoCRM', {});
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'removeAccessCRM - ' + error });
        return [];
    }
}

export {
    verAcesso,
    verAcessoCRM,
    verAcessoGESTAO,
    getUsuario,
    getRepresUsuario,
    getListaUsuario,
    putUser,
    postUser,
    patchUser,
    deleteUser,
    getPermissoesUsuario,
    removeAccessCRM,
    getEmailUsuario,
};
