// O único campo que não veio no JSON foi o fullMessage
const mapMessages = (msg) => ({
    code: msg.Codigo,
    message: msg.Mensagem,
    help: msg.Ajuda,
    fullMessage: `${msg.Codigo}: ${msg.Mensagem}`,
});

export const transformResponse = (json) => {
    const data = json ? JSON.parse(json) : {};

    const newData = {};

    // Extraindo os resultados do JSON
    const { result } = data;

    if (result) {
        // Pegando o primeiro resultado
        const [body] = result;

        if (body) {
            // Extraindo o conteúdo do JSON
            const { Resultado, Conteudo, Mensagens, MensagensSucesso } = body;

            // Obtendo os valores do JSON
            newData.result = Resultado;
            newData.content = Conteudo;
            newData.table = Conteudo?.TABELA;
            newData.data = Conteudo?.DADOS;

            // Calculando ou modificando valores através do JSON
            newData.isSuccessful = Resultado === '0';
            newData.hasData = newData.data && newData.data.length > 0;
            newData.messages = Mensagens ? Mensagens.map(mapMessages) : [];
            newData.messagesSuccess = MensagensSucesso ? MensagensSucesso.map(mapMessages) : [];

            // Funções para obter mais facilmente os dados
            newData.getFirstData = (defaultData = {}) => {
                if (!newData.hasData) return defaultData;
                const [firstData] = newData.data;
                return firstData;
            };

            // Pega a primeira mensagem completa ou uma mensagem default ou retorna o objeto inteiro de mensagem se passar true no segundo parâmetro
            newData.getFirstMessage = (defaultMsg = null, returnObject = false) => {
                const [firstMessageObj] = newData.messages;
                if (returnObject) return firstMessageObj;
                return firstMessageObj ? firstMessageObj.fullMessage : defaultMsg;
            };
        }
    }
    return newData;
};

export const jsonWithStructure = (data) => ({
    DADOS: [data],
});

export const jsonWithStructureWithOutArray = (data) => ({
    DADOS: data,
});

export default {
    transformResponse,
};
