import { ENTERPRISE } from 'helpers/api/enterpriseConst';
import { Navigate } from 'react-router-dom';

export const URL_INICIAL: string =
    ENTERPRISE.pubProduto() === 'GESTAO' ? 'dashboard/financeiro/indicador' : 'dashboard/crm/funilvendas';

const Root = () => {
    const getRootUrl = () => {
        let url: string = URL_INICIAL;
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
