function arredonda(n: number, casasDecimais: number) {
    const fator = Math.pow(10, casasDecimais);
    const valorArredondado = (Math.round(n * fator) / fator).toFixed(casasDecimais);
    return parseFloat(valorArredondado);
    // by CHATGPT
}
function formatStringToCurrency(n: string): string {
    n = n === null || n === undefined ? '0' : n;

    var numberStr = n.replace(',', '.');

    return Number(numberStr).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
}

function stringToNumber(n, InString: boolean = false): number {
    n = n === null || n === undefined ? '0' : n;

    let numberStr = String(n);
    if (String(n).indexOf(',') > 0) {
        numberStr = String(n).replaceAll('.', '');
        numberStr = numberStr.replace(',', '.');
    } else if (InString) {
        numberStr = String(n).replace('.', '');
    }
    return Number(numberStr);
}

function formatNumberToCurrency(n: number): string {
    n = n === null || n === undefined ? 0 : n;
    return n.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
}

function formatNumber(n: number | string, casasdecimais: number, maximoCasasDecimais?: number): string {
    const maxCasasDecimais = maximoCasasDecimais ? maximoCasasDecimais : casasdecimais;

    if (n === null || n === undefined) {
        n = 0;
    }

    return n.toLocaleString('pt-BR', {
        maximumFractionDigits: maxCasasDecimais,
        minimumFractionDigits: casasdecimais,
    });
}

function calculaPercentual(valorInicial, valorFinal: number): string {
    if (valorInicial !== 0) {
        var percentual = valorFinal / valorInicial;
        percentual = (percentual - 1) * 100;
    } else {
        percentual = 100;
    }

    return Number(percentual).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %';
}

const mascaraDecimalInput = ({
    separadorDeMilhares = '.',
    simboloDecimal = ',',
    casasDecimais = 2,
    separarMilhares = true,
} = {}) => {
    return (input) => {
        // var numero = (input.match(/\d/gi) || []).length;

        // if (numero <= casasDecimais) {
        //     return Array(casasDecimais).fill(/\d/);
        // }

        var valorString = input.toString().replace(/\D/g, '');
        var numero = valorString.length;

        if (numero <= 0) {
            return [simboloDecimal, ...Array(casasDecimais).fill(/\d/)];
        }

        // if (numero <= 0) {
        //     return [/\d/, simboloDecimal, ...Array(casasDecimais).fill(/\d/)];
        // }

        if (numero === casasDecimais + 1) {
            return [/\d/, /\d/, simboloDecimal, ...Array(casasDecimais).fill(/\d/)];
        }

        var mask = [];
        for (var i = numero - 1; i >= 0; i--) {
            mask.push(/\d/);
            if (i === numero - casasDecimais) {
                mask.push(simboloDecimal);
            }

            if (separarMilhares) {
                const r = numero - i;
                if (r >= casasDecimais + 2 && (r - casasDecimais) % 3 === 0 && i > 0) {
                    mask.push(separadorDeMilhares);
                }
            }
        }

        return mask.reverse();
    };
};

function formatCurrencyToNumber(value: string) {
    value = value?.replaceAll('.', '');
    value = value?.replace(',', '.');
    return value;
}

function StringToNumberConverter(value): number {
    const convertedNumber = parseFloat(value);
    return isNaN(convertedNumber) ? 0 : convertedNumber;
}

export {
    formatCurrencyToNumber,
    formatStringToCurrency,
    formatNumberToCurrency,
    stringToNumber,
    calculaPercentual,
    formatNumber,
    mascaraDecimalInput,
    arredonda,
    StringToNumberConverter,
};
