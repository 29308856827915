import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';
import { CustomProvider } from 'rsuite';
import ptBR from 'rsuite/locales/pt_BR';

const Routes = () => {
    return (
        <CustomProvider locale={ptBR}>
            <BrowserRouter>
                <AllRoutes />
            </BrowserRouter>
        </CustomProvider>
    );
};

export default Routes;
