import { errorMessageAPI } from 'helpers/api/messageUtils';
import { TModelNegocio } from 'helpers/model/negocio';
import { API, APILongTime } from '../../helpers/api/server';
import { jsonWithStructure } from 'helpers';
import { UNCLogs } from 'helpers/api/uncUtils';
import { initialValuesImportListBusinessType } from 'helpers/yup/validationImportListBusiness';

export type getNegocioType = {
    FILTRO_GERAL?: string;
    TIPO_DATA?: string;
    DATA_INICIAL?: string;
    DATA_FINAL?: string;
    LISTA_EMPRESACODIGO?: string;
    LISTA_REPRESCODIGO?: string;
    LISTA_USUARIOLOGIN?: string;
    NEGOCIO_CODIGO?: number;
    LISTA_RESPONSAVEL?: string;
    LISTA_SEGMENTOCODIGO?: string;
    LISTA_ORPROSPECTCODIGO?: string;
    LISTA_TEMPERATURA?: string;
    LISTA_ETIQUETA?: string;
    ATIVIDADES?: string;
    NEGOCIO_SITUACAO?: string;
    PIPELINE_CODIGO?: number;
    ESTAGIO_CODIGO?: number;
    SQL_LIMIT?: number;
    SQL_OFFSET?: number;
    LISTA_BOARDCODIGO?: string;
    LISTA_PIPELINECODIGO?: string;
    LISTA_ESTAGIOCODIGO?: string;
    PESSOA_CODIGO?: string;
    EXIBIR_HISTORICO?: string;
    DIAS_SEMINTERACAO?: number;
};

type GetloseBusinessType = {
    negocio_codigo: number;
    motperda_codigo: number;
    negocio_obsperda: string;
    board_codigo?: number;
    cancelaOrcamento?: string;
};

async function getNegocio(props: getNegocioType) {
    try {
        let json: any = {
            FILTRO_GERAL: props.FILTRO_GERAL ?? '',
            DATA_INICIAL: props.DATA_INICIAL ?? '1900-01-01',
            DATA_FINAL: props.DATA_FINAL ?? '2999-12-31',
            TIPO_DATA: props.TIPO_DATA,
            LISTA_EMPRESACODIGO: props.LISTA_EMPRESACODIGO,
            LISTA_REPRESCODIGO: props.LISTA_REPRESCODIGO,
            LISTA_USUARIOLOGIN: props.LISTA_USUARIOLOGIN,
            LISTA_RESPONSAVEL: props.LISTA_RESPONSAVEL,
            LISTA_SEGMENTOCODIGO: props.LISTA_SEGMENTOCODIGO,
            LISTA_ORPROSPECTCODIGO: props.LISTA_ORPROSPECTCODIGO,
            LISTA_TEMPERATURA: props.LISTA_TEMPERATURA,
            LISTA_ETIQUETA: props.LISTA_ETIQUETA,
            NEGOCIO_SITUACAO: props.NEGOCIO_SITUACAO,
            ATIVIDADES: props.ATIVIDADES,
            NEGOCIO_CODIGO: props.NEGOCIO_CODIGO,
            PIPELINE_CODIGO: props.PIPELINE_CODIGO,
            ESTAGIO_CODIGO: props.ESTAGIO_CODIGO,
            PESSOA_CODIGO: props.PESSOA_CODIGO,
            SQL_LIMIT: props.SQL_LIMIT,
            SQL_OFFSET: props.SQL_OFFSET,
            DIAS_SEMINTERACAO: props.DIAS_SEMINTERACAO,
        };

        if (props.LISTA_BOARDCODIGO) {
            json = { ...json, LISTA_BOARDCODIGO: props.LISTA_BOARDCODIGO };
        }

        if (props.LISTA_PIPELINECODIGO) {
            json = { ...json, LISTA_PIPELINECODIGO: props.LISTA_PIPELINECODIGO };
        }

        if (props.LISTA_ESTAGIOCODIGO) {
            json = { ...json, LISTA_ESTAGIOCODIGO: props.LISTA_ESTAGIOCODIGO };
        }

        if (props.EXIBIR_HISTORICO) {
            json = { ...json, EXIBIR_HISTORICO: props.EXIBIR_HISTORICO };
        }

        const { data } = await API.put('TWSNegocio/GetLista', json);

        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getNegocio - ' + error });
        return [];
    }
}

async function deleteBusiness(negocio_codigo: number) {
    try {
        const { data } = await API.delete(`/TWSNegocio/Negocio/${negocio_codigo}`);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'deleteBusiness - ' + error });
    }
}

async function registerBusiness(props) {
    try {
        const json = jsonWithStructure(props);

        const { data } = await API.put('TWSNegocio/Negocio', json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'registerBusiness - ' + error });
        return [];
    }
}

type UpdateBusinessType = {
    JSON: TModelNegocio;
};

async function updateBusiness(props: UpdateBusinessType) {
    try {
        const json = jsonWithStructure(props?.JSON);
        const { data } = await API.post(`/TWSNegocio/Negocio/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'updateBusiness - ' + error });
        return [];
    }
}

type putMoverNegocioType = {
    JSON: TModelNegocio;
};

async function putMoveBusiness(props: putMoverNegocioType) {
    try {
        const json = jsonWithStructure(props?.JSON);
        const { data } = await API.put(`/TWSNegocio/MoverNegocio/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'putMoveBusiness - ' + error });
        return [];
    }
}

type PatchBusinessType = {
    JSON: TModelNegocio;
};

async function patchBusiness(props: PatchBusinessType) {
    try {
        const json = jsonWithStructure(props?.JSON);
        const { data } = await API.post(`/TWSNegocio/PatchNegocio/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'patchBusiness - ' + error });
        return [];
    }
}

type putTrocaPipelineType = {
    JSON: TModelNegocio;
};

async function putTrocaPipeline(props: putTrocaPipelineType) {
    try {
        const json = jsonWithStructure(props?.JSON);
        const { data } = await API.put(`/TWSNegocio/TrocaPipeline/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'putTrocaPipeline - ' + error });
        return [];
    }
}

async function getPipelinesBoard(board_tipo: string) {
    try {
        const json = {
            board_tipo: board_tipo,
        };
        const { data } = await API.put(`/TWSNegocio/GetListaPipelinesBoard/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getPipelinesBoard - ' + error });
        return [];
    }
}

async function getEstagioByPipeline(pipeline_codigo: number | string) {
    try {
        const json = {
            pipeline_codigo: pipeline_codigo,
        };
        const { data } = await API.put(`/TWSNegocio/getListaEstagioByPipeline/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getEstagioByPipeline - ' + error });
        return [];
    }
}

async function getloseBusiness(props: GetloseBusinessType) {
    try {
        const json = {
            negocio_codigo: props.negocio_codigo,
            motperda_codigo: props.motperda_codigo,
            negocio_obsperda: props.negocio_obsperda,
            board_codigo: props?.board_codigo,
            cancelaOrcamento: props?.cancelaOrcamento,
        };
        const { data } = await API.put(`/TWSNegocio/PerdeNegocio/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getloseBusiness - ' + error });
        return [];
    }
}

type getFollowUpAndNotesType = {
    negocio_codigo: string;
    acomp_situacao: string;
};

async function getFollowUpAndNotes(props: getFollowUpAndNotesType) {
    try {
        const json = { negocio_codigo: props.negocio_codigo, acomp_situacao: props.acomp_situacao };
        const { data } = await API.put(`/TWSNegocio/GetListaAcompanhamentoAnotacao/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'GetFollowUpAndNotes - ' + error });
        return [];
    }
}

type getFavoriteBudgetType = {
    negocio_codigo: number;
    pedvenda_codigo: number;
    empresa_codigo: string;
};

async function favoriteBudget(props: getFavoriteBudgetType) {
    try {
        const json = {
            negocio_codigo: props.negocio_codigo,
            pedvenda_codigo: props.pedvenda_codigo,
            empresa_codigo: props.empresa_codigo,
        };
        const { data } = await API.put(`/TWSNegocio/FavoritarOrcamento/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'favoriteBudget - ' + error });
        return [];
    }
}

async function importBusiness(props: initialValuesImportListBusinessType) {
    try {
        const json = {
            board_codigo: props?.board_codigo,
            csv: props?.data_csv,
            distauto_codigo: props?.distauto_codigo,
            empresa_codigo: props?.empresa_codigo,
            estagio_codigo: props?.estagio_codigo,
            lista_etiquetacodigo: props?.etiqueta_codigo,
            orprospect_codigo: props?.orprospect_codigo,
            pipeline_codigo: props?.pipeline_codigo,
            repres_codigo: props?.repres_codigo,
            usuario_responsavel: props?.usuario_login,
        };

        const { data } = await API.put(`/TWSNegocio/ImportarCSVNegociosLinux/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'importBusiness - ' + error });
        return [];
    }
}

type putDesfazerMoverNegocioType = {
    NEGOCIO_CODIGO: number;
    CANCELAORCAMENTOS: string;
};

async function putUndoMoveBusiness(props: putDesfazerMoverNegocioType) {
    try {
        const json = { NEGOCIO_CODIGO: props.NEGOCIO_CODIGO, CANCELAORCAMENTOS: props.CANCELAORCAMENTOS };
        const { data } = await API.put(`/TWSNegocio/DesfazerMoverNegocio/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'putUndoMoveBusiness - ' + error });
        return [];
    }
}

type putReactivateBusinessType = {
    NEGOCIO_CODIGO: number;
};

async function putReactivateBusiness(props: putReactivateBusinessType) {
    try {
        const json = { NEGOCIO_CODIGO: props.NEGOCIO_CODIGO };
        const { data } = await API.put(`/TWSNegocio/ReativarNegocio/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'putReactivateBusiness - ' + error });
        return [];
    }
}

export type winBusinessType = {
    negocio_codigo: number;
    crmconfig_abrirnovanegociacao: boolean;
    crmconfig_enviarposvenda: boolean;
    pipeline_codigo?: number;
};

async function winBusinessAsync(json: winBusinessType) {
    try {
        const { data } = await API.put(`/TWSNegocio/GanhaNegocioLinux/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'winBusinessAsync - ' + error });
        return [];
    }
}

type winAfterSalesType = {
    negocio_codigo: number;
    crmconfig_abrirnovanegociacao: boolean;
};

async function winAfterSales(json: winAfterSalesType) {
    try {
        const { data } = await API.put(`/TWSNegocio/FinalizarPosVenda/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'winAfterSales - ' + error });
        return [];
    }
}

type BusinessDistributionType = {
    REPRESENTANTE_PADRAO: string;
    DISTAUTO_CODIGO: string;
    NEGOCIO: { negocio_codigo: string }[];
    RESPONSAVEL: { usuario_login: string }[];
};

async function representativeBusinessDistribution(props: BusinessDistributionType) {
    const json = jsonWithStructure(props);
    try {
        const { data } = await API.put(`/TWSNegocio/DistribuicaoNegocioRepresLinux/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'representativeBusinessDistribution - ' + error });
        return [];
    }
}

type addInternalBusinessUNCType = {
    pessoa_cnpj: string;
};

async function addInternalBusinessUNC(json: addInternalBusinessUNCType) {
    try {
        const { data } = await API.put(`/TWSNegocio/IncluirNegocioInternoUNC/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'addInternalBusinessUNC - ' + error });
        return [];
    }
}

type tagsDistributionType = {
    MODO_ADICIONARREMOVER: string;
    NEGOCIO: number[];
    ETIQUETA: { etiqueta_codigo: string }[];
};

async function tagsDistribution(props: tagsDistributionType) {
    const json = jsonWithStructure(props);
    try {
        const { data } = await API.put(`/TWSNegocio/AdicionarRemoverTagNegocioLote/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'tagsDistribution - ' + error });
        return [];
    }
}

type getCostumersDontBuyType = {
    limit?: string;
    offset?: string;
    dt_inicial: string;
    dt_final: string;
    empresa_codigo: string;
    segmento_codigo: string;
    orprospect_codigo: string;
    uf_codigo: string;
    cidade_codigo: string;
    bairro_codigo: string;
    repres_codigo: string;
    comprouanteriormente: string;
    tipo_filtrodata: string;
    pessoa_tipo: string;
};
async function getCostumersDontBuy(props: getCostumersDontBuyType) {
    const json = {
        limit: props.limit,
        offset: props.offset,
        dt_inicial: props.dt_inicial,
        dt_final: props.dt_final,
        empresa_codigo: props.empresa_codigo,
        segmento_codigo: props.segmento_codigo,
        orprospect_codigo: props.orprospect_codigo,
        uf_codigo: props.uf_codigo,
        cidade_codigo: props.cidade_codigo,
        bairro_codigo: props.bairro_codigo,
        repres_codigo: props.repres_codigo,
        comprouanteriormente: props.comprouanteriormente,
        tipo_filtrodata: props.tipo_filtrodata,
        pessoa_tipo: props.pessoa_tipo,
    };

    try {
        const { data } = await API.put(`/TWSNegocio/GetClientesQueNaoCompram/`, json);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'getCostumersDontBuy - ' + error });
        return [];
    }
}

async function insertCostumerDontBuy(props: any) {
    try {
        const { data } = await API.put(`/TWSNegocio/incluirClientesQueNaoCompramLinux/`, props);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'insertCostumerDontBuy - ' + error });
        return [];
    }
}

interface copyBusinessType {
    NEGOCIO_CODIGO: number;
    QTDEDIAS_INCLUSAO: number;
    COPIATAREFAS_PENDENTES: boolean;
    COPIAVENDA_GERAORCAMENTO: boolean;
}

async function copyBusiness(props: copyBusinessType) {
    try {
        const { data } = await API.put(`/TWSNegocio/CopiaNegocio/`, props);
        return data;
    } catch (error) {
        errorMessageAPI({ text: 'insertCostumerDontBuy - ' + error });
        return [];
    }
}

export {
    getNegocio,
    deleteBusiness,
    updateBusiness,
    patchBusiness,
    getPipelinesBoard,
    getEstagioByPipeline,
    getloseBusiness,
    registerBusiness,
    getFollowUpAndNotes,
    favoriteBudget,
    putTrocaPipeline,
    putMoveBusiness,
    winAfterSales,
    putUndoMoveBusiness,
    putReactivateBusiness,
    importBusiness,
    representativeBusinessDistribution,
    tagsDistribution,
    addInternalBusinessUNC,
    getCostumersDontBuy,
    insertCostumerDontBuy,
    copyBusiness,
    winBusinessAsync,
};
