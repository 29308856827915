import { getSearchCNPJ, getSearchCNPJR2D2 } from 'webservice/externo/CNPJ';
import { errorMessageAPI } from './messageUtils';

export function LoadDataFieldsCNPJ(event, setFieldValue: Function, setState?: Function) {
    const CNPJ = event?.target?.value;
    if (CNPJ || CNPJ !== '') {
        getSearchCNPJR2D2({ CNPJ: CNPJ }).then((responseGetCNPJ) => {
            if (responseGetCNPJ) {
                if (setState) {
                    // VAI SER USADO ESSA PROPS QUANDO OS DADOS
                    // DO ENDEREÇO ESTIVER USANDO STATE NORMAL E NN FORMIK
                    setState({
                        bairro_codigo: responseGetCNPJ?.data?.empresa_bairro,
                        endereco_cep: responseGetCNPJ?.data?.empresa_cep,
                        cidade_codigo: responseGetCNPJ?.data?.empresa_cidade,
                        endereco_cnpj: responseGetCNPJ?.data?.empresa_cnpj,
                        endereco_endereco: responseGetCNPJ?.data?.empresa_logradouro,
                        endereco_numero: responseGetCNPJ?.data?.empresa_numero,
                    });
                }
                setFieldValue('pessoa_razao', responseGetCNPJ?.data?.empresa_razao);
                setFieldValue('pessoa_fantasia', responseGetCNPJ?.data?.empresa_fantasia);
                setFieldValue('pessoa_telefone', responseGetCNPJ?.data?.empresa_telefone?.match(/^[^/]+/)?.[0]?.trim());
                setFieldValue(
                    'pessoa_telefone2',
                    responseGetCNPJ?.data?.empresa_telefone?.match(/^[^/]+/)?.[1]?.trim()
                );
                setFieldValue('pessoa_dtaberturaemp', responseGetCNPJ?.data?.empresa_dtabertura);
                setFieldValue('endereco_cep', responseGetCNPJ?.data?.empresa_cep);
                setFieldValue('endereco_numero', responseGetCNPJ?.data?.empresa_numero);
            } else {
                errorMessageAPI({ text: 'CNPJ não encontrado' });
            }
        });
    }
}

export function formatarCNPJ(cnpjsemformatacao) {
    // Remove qualquer caractere não numérico
    let cnpjcomformatacao = cnpjsemformatacao.replace(/\D/g, '');

    // Aplica a formatação do CNPJ (XX.XXX.XXX/XXXX-XX)
    cnpjcomformatacao = cnpjcomformatacao.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');

    return cnpjcomformatacao;
}
export async function ValidCNPJ(CNPJValue: string) {
    const CNPJ = CNPJValue;

    if (CNPJ || CNPJ !== '') {
        const responseGetCNPJ = await getSearchCNPJ({ CNPJ: CNPJ, showCatch: false });

        return responseGetCNPJ;
    }
    return false;
}
