import { createOptionsSelected } from './createOptionsSelected';
import { getRepresUsuario } from 'webservice/usuario';
import { ENTERPRISE } from './enterpriseConst';
import { CSSProperties } from 'react';
import { getUnicodeProcessos } from 'webservice/unicode_processos';
import { processingMessage, timerAutoClose } from './messageUtils';
import { transformResponse } from 'helpers/json';
import { SweetAlertIcon } from 'sweetalert2';
import { formatarCNPJ } from './CnpjUtils';
import { addInternalBusinessUNC } from 'webservice/negocio';

const UNCRotinas = ['edit', 'insert', 'view', 'delete'];
const UNCModal = ['cliente', 'endereco'];

export type UNCRotina = (typeof UNCRotinas)[number];
export type UNCModal = (typeof UNCRotinas)[number];

export function toUNCCase(val: string): string {
    return ENTERPRISE.pubUpperCase ? val.toUpperCase() : val;
}

export function UNCLogs(message?: any, ...optionalParams: any[]): void {
    if (process.env.REACT_APP_ENVIRONMENT === 'D') {
        console.log(message, ...optionalParams);
    }
}

export function UNCLogsTable(tabularData: any): void {
    if (process.env.REACT_APP_ENVIRONMENT === 'D') {
        console.table(tabularData);
    }
}

export function RetornaDescricaoRotina(rotina: UNCRotina): string {
    switch (rotina) {
        case 'edit': {
            return 'Alteração';
        }
        case 'insert': {
            return 'Inclusão';
        }
        case 'delete': {
            return 'Exclusão';
        }
        case 'view': {
            return 'Visualização';
        }
        default: {
            return 'Visualização';
        }
    }
}

export function retornaValue(value: string | any): any {
    try {
        if (value.value === undefined && value !== undefined) {
            return value;
        }
        return value.value;
    } catch {
        return value;
    }
}

export function retornaLabel(label: string | any): any {
    try {
        if (label.label === undefined && label !== undefined) {
            return label;
        }
        return label.label;
    } catch {
        return label;
    }
}

export const styleMask: CSSProperties = {
    display: 'block',
    width: '100%',
    padding: '0.42rem 0.9rem',
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'var(--ct-input-color)',
    backgroundColor: 'var(--ct-input-bg)',
    backgroundClip: 'padding-box',
    border: '1px solid var(--ct-input-border-color)',
    appearance: 'none',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
};

export const styleMaskDisable: CSSProperties = {
    display: 'block',
    width: '100%',
    padding: '0.42rem 0.9rem',
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'var(--ct-input-color)',
    backgroundColor: 'var(--ct-input-bg)',
    backgroundClip: 'padding-box',
    border: '1px solid var(--ct-input-border-color)',
    appearance: 'none',
    borderRadius: '0.25rem',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
};

export const getColorByInitial = (() => {
    const colorMap = new Map<string, string>();
    const colors = [
        '#FFDD57',
        // '#5C6BC0',
        '#26A69A',
        '#FF8A65',
        '#BA68C8',
        '#8D6E63',
        '#78909C',
        '#66BB6A',
        '#EC407A',
        // '#FFF176',
        '#26C6DA',
        '#9CCC65',
        '#FFB74D',
        '#9575CD',
        '#FF7F7F',
        '#26C6DA',
    ];
    let currentColorIndex = 0;
    return (initial: string): string => {
        if (colorMap.has(initial)) {
            return colorMap.get(initial)!;
        } else {
            const color = colors[currentColorIndex];
            currentColorIndex = (currentColorIndex + 1) % colors.length;
            colorMap.set(initial, color);
            return color;
        }
    };
})();

export function returnDefaultCompany(addValueInLabel: boolean = false) {
    const data = JSON.parse(ENTERPRISE.pubEmpresaPadrao());
    const defaultCompany = createOptionsSelected(
        data?.empresa_codigo,
        data?.empresa_razao + ' - ' + data?.empresa_cnpj,
        addValueInLabel
    );
    return defaultCompany;
}

export function returnDefaultUserLogged() {
    const data = JSON.parse(ENTERPRISE.pubUsuarioLogado());
    const defaultUserLogged = createOptionsSelected(data?.login, data?.nome);
    return defaultUserLogged;
}

export async function returnRepresUser(usuario_login: string) {
    if (usuario_login !== '') {
        const data = await getRepresUsuario(retornaValue(usuario_login));

        if (data?.result === '1' || !data?.content?.DADOS[0]?.REPRESENTANTE[0]) {
            const defaultRepresUser = createOptionsSelected('', '');
            return defaultRepresUser;
        } else {
            const repres_codigo = data?.content?.DADOS[0]?.REPRESENTANTE[0]?.repres_codigo;
            const repres_razao = data?.content?.DADOS[0]?.REPRESENTANTE[0]?.repres_razao;

            const defaultRepresUser = createOptionsSelected(repres_codigo, repres_razao);
            return defaultRepresUser;
        }
    } else {
        const defaultRepresUser = createOptionsSelected('', '');
        return defaultRepresUser;
    }
}

export type colorUtilsUNCType =
    | ''
    | 'green'
    | 'red'
    | 'yellow'
    | 'lightblue'
    | 'lightgray'
    | 'gray'
    | 'empresarial'
    | 'tectum'
    | 'split'
    | 'industrialmais'
    | 'transparent'
    | 'darkgray'
    | 'black'
    | 'darkgray'
    | 'cold'
    | 'warm'
    | 'hot'
    | 'veryhot'
    | 'blue'
    | 'white'
    | 'lightyellow';
export function colorUtilsUNC(color: colorUtilsUNCType): string {
    var returnColorUNC: string;

    switch (color) {
        case '': {
            returnColorUNC = '';
            break;
        }
        case 'green': {
            returnColorUNC = '#0acf97';
            break;
        }
        case 'red': {
            returnColorUNC = '#fa5c7c';
            break;
        }
        case 'yellow': {
            returnColorUNC = '#ffbc00';
            break;
        }
        case 'lightblue': {
            returnColorUNC = '#39afd1';
            break;
        }
        case 'lightgray': {
            returnColorUNC = '#e6e6e6';
            break;
        }
        case 'gray': {
            returnColorUNC = '#6e6e6e';
            break;
        }
        case 'empresarial': {
            returnColorUNC = '#eec01c';
            break;
        }
        case 'tectum': {
            returnColorUNC = '#3a53bf';
            break;
        }
        case 'split': {
            returnColorUNC = '#ed9222';
            break;
        }
        case 'industrialmais': {
            returnColorUNC = '#4586e9';
            break;
        }
        case 'transparent': {
            returnColorUNC = 'transparent';
            break;
        }
        case 'darkgray': {
            returnColorUNC = '#3b3b3b';
            break;
        }
        case 'black': {
            returnColorUNC = '#000000';
            break;
        }
        case 'cold': {
            returnColorUNC = '#9ac0fc';
            break;
        }
        case 'warm': {
            returnColorUNC = '#fa913c';
            break;
        }
        case 'hot': {
            returnColorUNC = '#ff0000';
            break;
        }
        case 'veryhot': {
            returnColorUNC = '#871616';
            break;
        }
        case 'blue': {
            returnColorUNC = '#2e42e8';
            break;
        }
        case 'white': {
            returnColorUNC = '#ffffff';
            break;
        }
        case 'lightyellow': {
            returnColorUNC = '#ffe869';
            break;
        }
    }

    return returnColorUNC;
}

export type iconUtilsUNCType =
    | ''
    | 'information'
    | 'save'
    | 'close'
    | 'money-plus'
    | 'money'
    | 'heart'
    | 'heart-outline'
    | 'star'
    | 'star-outline'
    | 'close-circle-outline'
    | 'arrow-down'
    | 'dots-vertical'
    | 'delete'
    | 'printer'
    | 'delete-circle-outline'
    | 'cancel'
    | 'calendar'
    | 'truck-fast'
    | 'pencil'
    | 'account-plus'
    | 'plus'
    | 'restore'
    | 'arrow-right'
    | 'arrow-left'
    | 'office'
    | 'paperclip'
    | 'whatsapp'
    | 'calendar-star';

export function iconUtilsUNC(icon: iconUtilsUNCType): string {
    var returnIconUNC: string;

    switch (icon) {
        case '': {
            returnIconUNC = '';
            break;
        }
        case 'save': {
            returnIconUNC = 'mdi mdi-content-save';
            break;
        }
        case 'close': {
            returnIconUNC = 'mdi mdi-close';
            break;
        }
        case 'money-plus': {
            returnIconUNC = 'mdi mdi-cash-plus';
            break;
        }
        case 'money': {
            returnIconUNC = 'mdi mdi-cash';
            break;
        }
        case 'heart': {
            returnIconUNC = 'mdi mdi-heart';
            break;
        }
        case 'heart-outline': {
            returnIconUNC = 'mdi mdi-heart-outline';
            break;
        }
        case 'star': {
            returnIconUNC = 'mdi mdi-star';
            break;
        }
        case 'star-outline': {
            returnIconUNC = 'mdi mdi-star-outline';
            break;
        }
        case 'close-circle-outline': {
            returnIconUNC = 'mdi mdi-close-circle-outline';
            break;
        }
        case 'arrow-down': {
            returnIconUNC = 'mdi mdi-chevron-down';
            break;
        }
        case 'dots-vertical': {
            returnIconUNC = 'mdi mdi-dots-vertical';
            break;
        }
        case 'delete': {
            returnIconUNC = 'mdi mdi-delete';
            break;
        }
        case 'printer': {
            returnIconUNC = 'mdi mdi-printer';
            break;
        }
        case 'delete-circle-outline': {
            returnIconUNC = 'mdi mdi-delete-circle-outline';
            break;
        }
        case 'cancel': {
            returnIconUNC = 'mdi mdi-cancel';
            break;
        }
        case 'calendar': {
            returnIconUNC = 'mdi mdi-calendar';
            break;
        }
        case 'truck-fast': {
            returnIconUNC = 'mdi mdi-truck-fast';
            break;
        }
        case 'pencil': {
            returnIconUNC = 'mdi mdi-pencil';
            break;
        }
        case 'account-plus': {
            returnIconUNC = 'mdi mdi-account-plus';
            break;
        }
        case 'plus': {
            returnIconUNC = 'mdi mdi-plus';
            break;
        }
        case 'restore': {
            returnIconUNC = 'mdi mdi-restore';
            break;
        }
        case 'arrow-right': {
            returnIconUNC = 'mdi mdi-arrow-right-bold';
            break;
        }
        case 'arrow-left': {
            returnIconUNC = 'mdi mdi-arrow-left-bold';
            break;
        }
        case 'office': {
            returnIconUNC = 'mdi mdi-office-building';
            break;
        }
        case 'paperclip': {
            returnIconUNC = 'mdi mdi-paperclip';
            break;
        }
        case 'whatsapp': {
            returnIconUNC = 'mdi mdi-whatsapp';
            break;
        }
        case 'calendar-star': {
            returnIconUNC = 'mdi mdi-calendar-star';
            break;
        }
        case 'information': {
            returnIconUNC = 'mdi mdi-information';
            break;
        }
    }

    return returnIconUNC;
}

export function detachArrayToSemiColumn(objArray): string {
    const values: number[] = [];

    for (const obj of objArray) {
        if (obj.value && typeof obj.value === 'number') {
            values.push(obj.value);
        }
    }

    return values.join(';');
}

type addBusinessUNCType = {
    linkOpen: string;
};

export async function addBusinessUNC(props: addBusinessUNCType) {
    window.open(props.linkOpen, '_blank');
    const json = {
        pessoa_cnpj: formatarCNPJ(ENTERPRISE.pubCNPJReg()),
    };
    await addInternalBusinessUNC(json);
}
//export const CASASDECIMAIS_VALORUNITARIO = 2;
export const CASASDECIMAIS_VALOR = 2;
export const CASASDECIMAIS_PERCENTUAL = 6;

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

type processType = {
    message: string;
    help: string;
    title?: string;
    intervalToClose?: number;
    icon?: SweetAlertIcon;
    timerProgressBar?: boolean;
};

export const Processando = async (process: processType) => {
    process.title = process.title ? process.title : 'Aguarde, processando...';

    processingMessage({
        title: process.title,
        html: process.message + '<br>' + process.help,
        timer: process.intervalToClose,
        timerProgressBar: process.timerProgressBar,
        icon: process.icon,
    });
};
export async function AguardeWSProcessando(piUNIPROC_CODIGO: number): Promise<void> {
    const ciQTDE_CONEXAO: number = 50;
    const ciQTDE_TENTATIVAS: number = 150;

    const ciDelayInicio: number = 2000;
    //ESSE É O TEMPO DE BUSCA PARA INICO DO WS
    //ELE BUSCA x ciQTDE_CONEXAO. Então o processamento Hoje iniciar de 2 à 10 segundos;

    const ciDelayAcompanhamento: number = 5000; //ESSE É O TEMPO DE  ESPERA ENTRE BUSCA DO WS
    //ELE BUSCA x ciQTDE_TENTATIVAS. Então o processamento Hoje de acompanhamento de 5 à 150 segundos;
    //SE FICA 50 segundos sem "resposta" ele vai fechar o processamento
    //Acho pouco tempo

    try {
        let viCONEXAO: number = 1;

        Processando({
            message: 'Iniciando Processando...',
            help: ' Verificando processamentos...',
        });
        await delay(ciDelayInicio);

        let consumoUnicodeProcessos = await getUnicodeProcessos({
            uniproc_codigo: piUNIPROC_CODIGO,
            //uniproc_situacao: 'EE',
        });

        let aguadarProcesso: boolean = consumoUnicodeProcessos.result === '1';

        while (aguadarProcesso) {
            Processando({
                message: `Tentativa ${viCONEXAO} de ${ciQTDE_CONEXAO} ...`,
                help: 'Verificando processamentos...',
            });
            await delay(ciDelayInicio);

            consumoUnicodeProcessos = await getUnicodeProcessos({
                uniproc_codigo: piUNIPROC_CODIGO,
                //uniproc_situacao: 'EE',
            });

            viCONEXAO++;
            aguadarProcesso = consumoUnicodeProcessos.result === '1' || viCONEXAO <= ciQTDE_CONEXAO;
        }

        let vbPARANDO: boolean = false;
        let vbPARADO: boolean = false;
        let viTENTATIVAS: number = 0;
        let viULTIMA_POSICAO: number = 0;
        let vsULTIMA_MENSAGEM: string = '';

        while (consumoUnicodeProcessos.result === '0' && !vbPARADO) {
            consumoUnicodeProcessos = await getUnicodeProcessos({
                uniproc_codigo: piUNIPROC_CODIGO,
                uniproc_situacao: 'EE',
            });

            if (consumoUnicodeProcessos.result === '0') {
                vbPARANDO =
                    vsULTIMA_MENSAGEM === consumoUnicodeProcessos.data[0].uniproc_mensagem &&
                    viULTIMA_POSICAO === consumoUnicodeProcessos.data[0].uniproc_registroatual;

                if (vbPARANDO) {
                    viTENTATIVAS++;
                    vbPARADO = viTENTATIVAS >= ciQTDE_TENTATIVAS;
                } else {
                    viTENTATIVAS = 0;
                }

                vsULTIMA_MENSAGEM = consumoUnicodeProcessos.data[0].uniproc_mensagem;
                viULTIMA_POSICAO = consumoUnicodeProcessos.data[0].uniproc_registroatual;

                Processando({
                    message: consumoUnicodeProcessos.data[0].uniproc_mensagem,
                    help: vbPARANDO
                        ? ` Processando CONEXÃO... Aguardando para Reestabelecer ${viTENTATIVAS} ...`
                        : consumoUnicodeProcessos.data[0].uniproc_observacao,
                });
                await delay(ciDelayAcompanhamento);
            }
        }

        //ULTIMA BUSCA PARA OBTER RESULTADO
        consumoUnicodeProcessos = await getUnicodeProcessos({
            uniproc_codigo: piUNIPROC_CODIGO,
        });

        Processando({
            message: consumoUnicodeProcessos.data[0].uniproc_mensagem,
            help: 'Concluído',
            title: ' Concluindo processamento',
            intervalToClose: ciDelayInicio,
            timerProgressBar: true,
            // icon: 'success',
        });

        return consumoUnicodeProcessos.data?.[0].uniproc_resultadoobject[0] as any;
    } finally {
    }
}
