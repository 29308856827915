export function getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(String(reader.result));
        reader.onerror = reject;
    });
}

export function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function isShowableFile(url: string): Boolean {
    // ao incluir mais visualizadores colocar || e as outras funções
    // exemplo
    // return isImageExtension(url) || isPdfExtension(url);
    return isImageExtension(url);
}

export function isImageExtension(extCompare: string) {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp']; // Adicione outras extensões de imagem, se necessário
    const ext = extCompare.slice(extCompare.lastIndexOf('.')).toLowerCase();
    return imageExtensions.includes(ext);
}
