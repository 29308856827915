import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const ResetPassword = React.lazy(() => import('pages/account/ResetPassword'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));
const CreateNewAccount = React.lazy(() => import('pages/account/CreateNewAccount'));
const NewAccountInfo = React.lazy(() => import('pages/account/NewAccountMoreInfo'));

const Login2 = React.lazy(() => import('pages/account/Login2'));
const Logout2 = React.lazy(() => import('pages/account/Logout2'));
const Register2 = React.lazy(() => import('pages/account/Register2'));
const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2'));

// dashboard
// const AnalyticsDashboard = React.lazy(() => import('pages/dashboard/Analytics'));
// const EcommerceDashboard = React.lazy(() => import('pages/dashboard/Ecommerce'));
// const ProjectDashboard = React.lazy(() => import('pages/dashboard/Project'));
// const EWalletDashboard = React.lazy(() => import('pages/dashboard/E-Wallet'));

// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));
const ProjectList = React.lazy(() => import('pages/apps/Projects/List'));
const ProjectDetail = React.lazy(() => import('pages/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(() => import('pages/apps/Projects/Gantt'));
const ProjectForm = React.lazy(() => import('pages/apps/Projects/ProjectForm'));

// - chat
const ChatApp = React.lazy(() => import('pages/apps/Chat/'));
const FileViewer = React.lazy(() => import('pages/apps/FileViewer/'));

// -crm
const CRMDashboard = React.lazy(() => import('pages/dashboard/CRM/Indicador-template'));

// GRAFICOS - CRM
const CRMGraficoProspects = React.lazy(() => import('pages/dashboard/CRM/GraficoProspect/'));
const CRMGraficoLeads = React.lazy(() => import('pages/dashboard/CRM/GraficoLeads/'));
const CRMGraficoVendas = React.lazy(() => import('pages/dashboard/CRM/GraficoVendas/'));
const CRMGraficoOportunidades = React.lazy(() => import('pages/dashboard/CRM/GraficoOportunidades/'));
// FIM GRAFICOS - CRM

const CRMProjects = React.lazy(() => import('pages/apps/CRM_bkp/Projects'));
const CRMManagement = React.lazy(() => import('pages/apps/CRM_bkp/Management'));
const CRMClients = React.lazy(() => import('pages/apps/CRM_bkp/Clients'));
const CRMOrderList = React.lazy(() => import('pages/apps/CRM_bkp/OrderList'));
const CRMKanban = React.lazy(() => import('pages/apps/CRM_bkp/Tasks/Board/'));

// - crm
const CRMCadastros = React.lazy(() => import('pages/apps/crm/Cadastros/'));
const CRMPipeline = React.lazy(() => import('pages/apps/crm/Pipeline'));
const CRMManutencaoNegocio = React.lazy(() => import('pages/apps/crm/Pipeline/ManutencaoNegocio/'));
const CRMAtividades = React.lazy(() => import('pages/apps/crm/Atividades/'));
const CRMMotivoPerda = React.lazy(() => import('pages/apps/crm/MotivoPerda/'));
const CRMTipoAtividade = React.lazy(() => import('pages/apps/crm/TipoAtividade/'));
const FunilVendas = React.lazy(() => import('pages/dashboard/CRM/FunilVendas'));
const CrmDashboard = React.lazy(() => import('pages/dashboard/CRM/GraficoTarefas'));
const CRMSegmento = React.lazy(() => import('pages/apps/crm/Segmento/'));
const CRMOrigemContato = React.lazy(() => import('pages/apps/crm/OrigemContato/'));
const CRMBoard = React.lazy(() => import('pages/apps/crm/Board/'));
const CRMEtiqueta = React.lazy(() => import('pages/apps/crm/Etiqueta/'));
const CRMListaPipeline = React.lazy(() => import('pages/apps/crm/Pipeline/ListaPipeline'));
const CRMEstagio = React.lazy(() => import('pages/apps/crm/Estagio/ListaEstagio/'));
const CRMRedistribuicaoDeCarteira = React.lazy(() => import('pages/apps/crm/RedistribuicaoDeCarteira/'));
const CRMRedistribuicaoDeTags = React.lazy(() => import('pages/apps/crm/RedistribuicaoDeTags/'));
const CRMClientesQueNaoCompram = React.lazy(() => import('pages/apps/crm/ClientesQueNaoCompram/'));
const CRMDistribuicaoAutomatica = React.lazy(() => import('pages/apps/crm/DistribuicaoAutomatica/'));
const CRMImportacaoNegocio = React.lazy(() => import('pages/apps/crm/ImportacaoDeNegocio/'));

// - pessoa
const CRMPessoa = React.lazy(() => import('pages/apps/Cadastros/Pessoa'));

// - usuario
const CRMUsuario = React.lazy(() => import('pages/apps/Cadastros/Usuario'));

// - perfil
const CRMPerfil = React.lazy(() => import('pages/apps/Cadastros/Perfil'));

// -vendas
const VendasDashboard = React.lazy(() => import('pages/dashboard/Vendas/Indicador'));
const VendasAnaliseComparativa = React.lazy(() => import('pages/dashboard/Vendas/AnaliseComparativa'));
const ListagemVendas = React.lazy(() => import('pages/apps/Vendas/ListagemVendas/Board/'));
const CadastroVendas = React.lazy(() => import('pages/apps/Vendas/CadastroVendas/'));

// -financeiro
const FinanceiroDashboard = React.lazy(() => import('pages/dashboard/Financeiro/Indicador'));
const FinanceiroAnaliseComparativa = React.lazy(() => import('pages/dashboard/Financeiro/AnaliseComparativa'));

// -compras
const ComprasSolicitacao = React.lazy(() => import('pages/dashboard/Compras/IndicadorSolicitacao'));
const ComprasCotacao = React.lazy(() => import('pages/dashboard/Compras/IndicadorCotacao'));
const ComprasPedido = React.lazy(() => import('pages/dashboard/Compras/IndicadorPedidoCompra'));

// -producao
const ProducaoDashboard = React.lazy(() => import('pages/dashboard/Producao/Indicador'));

// -estoque
const EstoqueDashboard = React.lazy(() => import('pages/dashboard/Estoque/Indicador'));

// -UNC PIX
const AbrirConta = React.lazy(() => import('pages/apps/UNCPix/AbrirConta/'));
const ExtratoSaldo = React.lazy(() => import('pages/apps/UNCPix/ExtratoSaldo/'));
const ContaTransferencia = React.lazy(() => import('pages/apps/UNCPix/ContaTransferencia/'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('pages/apps/Ecommerce/Sellers'));

// - email
const Inbox = React.lazy(() => import('pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('pages/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(() => import('pages/apps/SocialFeed/'));

// - tasks
const TaskList = React.lazy(() => import('pages/apps/Tasks/List/'));
const TaskDetails = React.lazy(() => import('pages/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('pages/apps/Tasks/Board/'));
// - file
const FileManager = React.lazy(() => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages/profile'));
const Profile2 = React.lazy(() => import('pages/profile2'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const TestExpired = React.lazy(() => import('pages/error/TestExpired'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('pages/other/Invoice'));
const FAQ = React.lazy(() => import('pages/other/FAQ'));
const Pricing = React.lazy(() => import('pages/other/Pricing'));
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));
const Starter = React.lazy(() => import('pages/other/Starter'));
const PreLoader = React.lazy(() => import('pages/other/PreLoader/'));
const Timeline = React.lazy(() => import('pages/other/Timeline'));

const Landing = React.lazy(() => import('pages/landing/'));

// uikit
const Accordions = React.lazy(() => import('pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('pages/uikit/Alerts'));
const Avatars = React.lazy(() => import('pages/uikit/Avatars'));
const Badges = React.lazy(() => import('pages/uikit/Badges'));
const Breadcrumbs = React.lazy(() => import('pages/uikit/Breadcrumb'));
const Buttons = React.lazy(() => import('pages/uikit/Buttons'));
const Cards = React.lazy(() => import('pages/uikit/Cards'));
const Carousels = React.lazy(() => import('pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('pages/uikit/Dropdowns'));
const EmbedVideo = React.lazy(() => import('pages/uikit/EmbedVideo'));
const Grid = React.lazy(() => import('pages/uikit/Grid'));
const ListGroups = React.lazy(() => import('pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('pages/uikit/Modals'));
const Notifications = React.lazy(() => import('pages/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('pages/uikit/Offcanvas'));
const Placeholders = React.lazy(() => import('pages/uikit/Placeholders'));
const Paginations = React.lazy(() => import('pages/uikit/Paginations'));
const Popovers = React.lazy(() => import('pages/uikit/Popovers'));
const Progress = React.lazy(() => import('pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('pages/uikit/Ribbons'));
const Spinners = React.lazy(() => import('pages/uikit/Spinners'));
const Tabs = React.lazy(() => import('pages/uikit/Tabs'));
const Tooltips = React.lazy(() => import('pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('pages/uikit/Typography'));
const DragDrop = React.lazy(() => import('pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('pages/uikit/Ratings'));

// icons
const Dripicons = React.lazy(() => import('pages/icons/Dripicons'));
const MDIIcons = React.lazy(() => import('pages/icons/MDIIcons'));
const Unicons = React.lazy(() => import('pages/icons/Unicons'));

// forms
const BasicForms = React.lazy(() => import('pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('pages/forms/Validation'));
const FormWizard = React.lazy(() => import('pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('pages/forms/FileUpload'));
const Editors = React.lazy(() => import('pages/forms/Editors'));

// charts
const ApexChart = React.lazy(() => import('pages/charts/Apex'));
const ChartJs = React.lazy(() => import('pages/charts/ChartJs'));

// tables
const BasicTables = React.lazy(() => import('pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('pages/tables/Advanced'));

// widgets
const Widgets = React.lazy(() => import('pages/uikit/Widgets'));

// maps
const GoogleMaps = React.lazy(() => import('pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('pages/maps/VectorMaps'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'resetPassword/:hash', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                        { path: 'create-new-account', element: <LoadComponent component={CreateNewAccount} /> },
                        { path: 'new-account-more-info', element: <LoadComponent component={NewAccountInfo} /> },
                    ],
                },

                {
                    path: 'test-expired',
                    element: <LoadComponent component={TestExpired} />,
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
                {
                    path: 'fileviewer/:url',
                    element: <LoadComponent component={FileViewer} />,
                },

                {
                    path: '*',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute /* roles={'Admin'} */ component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'vendas',
                            children: [
                                {
                                    path: 'indicador',
                                    element: <LoadComponent component={VendasDashboard} />,
                                },

                                {
                                    path: 'analisecomparativa',
                                    element: <LoadComponent component={VendasAnaliseComparativa} />,
                                },
                            ],
                        },
                        {
                            path: 'financeiro',
                            children: [
                                {
                                    path: 'indicador',
                                    element: <LoadComponent component={FinanceiroDashboard} />,
                                },
                                {
                                    path: 'analisecomparativa',
                                    element: <LoadComponent component={FinanceiroAnaliseComparativa} />,
                                },
                            ],
                        },
                        {
                            path: 'compras',
                            children: [
                                {
                                    path: 'indicadorsolicitacao',
                                    element: <LoadComponent component={ComprasSolicitacao} />,
                                },
                                {
                                    path: 'indicadorcotacao',
                                    element: <LoadComponent component={ComprasCotacao} />,
                                },
                                {
                                    path: 'indicadorpedido',
                                    element: <LoadComponent component={ComprasPedido} />,
                                },
                            ],
                        },
                        {
                            path: 'producao',
                            children: [
                                {
                                    path: 'indicador',
                                    element: <LoadComponent component={ProducaoDashboard} />,
                                },
                            ],
                        },
                        {
                            path: 'estoque',
                            children: [
                                {
                                    path: 'indicador',
                                    element: <LoadComponent component={EstoqueDashboard} />,
                                },
                            ],
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'funilvendas',
                                    element: <LoadComponent component={FunilVendas} />,
                                },
                                {
                                    path: 'indicador',
                                    element: <LoadComponent component={CrmDashboard} />,
                                },

                                { path: 'etiqueta', element: <LoadComponent component={CRMEtiqueta} /> },
                                { path: 'graficovendas', element: <LoadComponent component={CRMGraficoVendas} /> },
                                {
                                    path: 'graficoprospect',
                                    element: <LoadComponent component={CRMGraficoProspects} />,
                                },
                                { path: 'graficoleads', element: <LoadComponent component={CRMGraficoLeads} /> },
                                {
                                    path: 'graficooportunidades',
                                    element: <LoadComponent component={CRMGraficoOportunidades} />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'calendar',
                            element: <LoadComponent component={CalendarApp} />,
                        },
                        {
                            path: 'chat',
                            element: <LoadComponent component={ChatApp} />,
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'dashboard-template',
                                    element: <LoadComponent component={CRMDashboard} />,
                                },
                                {
                                    path: 'projects',
                                    element: <LoadComponent component={CRMProjects} />,
                                },
                                {
                                    path: 'management',
                                    element: <LoadComponent component={CRMManagement} />,
                                },
                                {
                                    path: 'clients',
                                    element: <LoadComponent component={CRMClients} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={CRMOrderList} />,
                                },

                                {
                                    path: 'tasks',
                                    children: [
                                        {
                                            path: 'kanban',
                                            element: <LoadComponent component={CRMKanban} />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'vendas',
                            children: [
                                {
                                    path: 'listagemvendas',
                                    children: [
                                        {
                                            path: 'listavendas',
                                            element: <LoadComponent component={ListagemVendas} />,
                                        },
                                    ],
                                },

                                {
                                    path: 'cadastrovendas',
                                    children: [
                                        {
                                            path: 'cadastraVendas',
                                            element: <LoadComponent component={CadastroVendas} />,
                                        },
                                    ],
                                },
                                {
                                    path: 'cadastrovendas',
                                    children: [
                                        {
                                            path: 'dadosVendas/:empresa_codigo/:pedvenda_codigo',
                                            element: <LoadComponent component={CadastroVendas} />,
                                        },
                                    ],
                                },
                                {
                                    path: 'cadastrovendas',
                                    children: [
                                        {
                                            path: 'dadosVendasNegocio/:empresa_codigo/:pedvenda_codigo/:negocio_codigo',
                                            element: <LoadComponent component={CadastroVendas} />,
                                        },
                                    ],
                                },
                                {
                                    path: 'cadastrovendas',
                                    children: [
                                        {
                                            path: 'cadastraOrcamentoNegocio/:negocio_codigo',
                                            element: <LoadComponent component={CadastroVendas} />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'cadastros',
                                    element: <LoadComponent component={CRMCadastros} />,
                                },
                                {
                                    path: 'pipeline',
                                    children: [
                                        {
                                            path: ':board_type/:pipeline_type',
                                            element: <LoadComponent component={CRMPipeline} />,
                                        },
                                        {
                                            path: ':board_type',
                                            element: <LoadComponent component={CRMPipeline} />,
                                        },
                                    ],
                                },
                                {
                                    path: 'atividades',
                                    element: <LoadComponent component={CRMAtividades} />,
                                },
                                {
                                    path: 'motivoperda',
                                    element: <LoadComponent component={CRMMotivoPerda} />,
                                },
                                {
                                    path: 'segmento',
                                    element: <LoadComponent component={CRMSegmento} />,
                                },

                                { path: 'board', element: <LoadComponent component={CRMBoard} /> },
                                {
                                    path: 'pessoa',
                                    element: <LoadComponent component={CRMPessoa} />,
                                },
                                {
                                    path: 'pipeline',
                                    children: [
                                        {
                                            path: 'manutencaonegocio/:negocio_codigo',
                                            element: <LoadComponent component={CRMManutencaoNegocio} />,
                                        },
                                        {
                                            path: 'listapipeline/',
                                            element: <LoadComponent component={CRMListaPipeline} />,
                                        },
                                    ],
                                },

                                {
                                    path: 'estagio',
                                    children: [
                                        {
                                            path: 'listaestagio',
                                            element: <LoadComponent component={CRMEstagio} />,
                                        },
                                    ],
                                },
                                { path: 'tipoatividade', element: <LoadComponent component={CRMTipoAtividade} /> },
                                {
                                    path: 'origemContato',
                                    element: <LoadComponent component={CRMOrigemContato} />,
                                },
                                { path: 'etiqueta', element: <LoadComponent component={CRMEtiqueta} /> },
                                {
                                    path: 'distribuicaoAutomatica',
                                    element: <LoadComponent component={CRMDistribuicaoAutomatica} />,
                                },
                                { path: 'board', element: <LoadComponent component={CRMBoard} /> },
                                {
                                    path: 'usuario',
                                    element: <LoadComponent component={CRMUsuario} />,
                                },
                                {
                                    path: 'perfil',
                                    element: <LoadComponent component={CRMPerfil} />,
                                },
                                {
                                    path: 'importacaodenegocio',
                                    element: <LoadComponent component={CRMImportacaoNegocio} />,
                                },
                                {
                                    path: 'redistribuicaodecarteira',
                                    element: <LoadComponent component={CRMRedistribuicaoDeCarteira} />,
                                },
                                {
                                    path: 'redistribuicaodeTags',
                                    element: <LoadComponent component={CRMRedistribuicaoDeTags} />,
                                },
                                {
                                    path: 'clientesQueNaoCompram',
                                    element: <LoadComponent component={CRMClientesQueNaoCompram} />,
                                },
                            ],
                        },
                        {
                            path: 'financeiro',
                            children: [
                                {
                                    path: 'indicador',
                                    element: <LoadComponent component={FinanceiroDashboard} />,
                                },
                            ],
                        },
                        {
                            path: 'uncpix',
                            children: [
                                {
                                    path: 'abrirConta/:empresa_codigo',
                                    element: <LoadComponent component={AbrirConta} />,
                                },
                                {
                                    path: 'extratoSaldo/:empresa_codigo',
                                    element: <LoadComponent component={ExtratoSaldo} />,
                                },
                                {
                                    path: 'abrirConta',
                                    element: <LoadComponent component={AbrirConta} />,
                                },
                                {
                                    path: 'extratoSaldo',
                                    element: <LoadComponent component={ExtratoSaldo} />,
                                },
                                {
                                    path: 'contaTransferencia',
                                    element: <LoadComponent component={ContaTransferencia} />,
                                },
                            ],
                        },
                        {
                            path: 'ecommerce',
                            children: [
                                {
                                    path: 'products',
                                    element: <LoadComponent component={EcommerceProducts} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProductDetails} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={Orders} />,
                                },
                                {
                                    path: 'order/details',
                                    element: <LoadComponent component={OrderDetails} />,
                                },
                                {
                                    path: 'customers',
                                    element: <LoadComponent component={Customers} />,
                                },
                                {
                                    path: 'shopping-cart',
                                    element: <LoadComponent component={Cart} />,
                                },
                                {
                                    path: 'checkout',
                                    element: <LoadComponent component={Checkout} />,
                                },
                                {
                                    path: 'sellers',
                                    element: <LoadComponent component={Sellers} />,
                                },
                            ],
                        },
                        {
                            path: 'email',
                            children: [
                                {
                                    path: 'inbox',
                                    element: <LoadComponent component={Inbox} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={EmailDetail} />,
                                },
                            ],
                        },
                        {
                            path: 'tasks',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={TaskList} />,
                                },
                                {
                                    path: 'kanban',
                                    element: <LoadComponent component={Kanban} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={TaskDetails} />,
                                },
                            ],
                        },

                        {
                            path: 'projects',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={ProjectList} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProjectDetail} />,
                                },
                                {
                                    path: 'gantt',
                                    element: <LoadComponent component={ProjectGannt} />,
                                },
                                {
                                    path: 'new',
                                    element: <LoadComponent component={ProjectForm} />,
                                },
                            ],
                        },
                        {
                            path: 'social',
                            element: <LoadComponent component={SocialFeed} />,
                        },
                        {
                            path: 'file',
                            element: <LoadComponent component={FileManager} />,
                        },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                        {
                            path: 'profile2',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },

                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
                    ],
                },
                {
                    path: 'ui',
                    children: [
                        {
                            path: 'base-ui',
                            children: [
                                {
                                    path: 'accordions',
                                    element: <LoadComponent component={Accordions} />,
                                },
                                {
                                    path: 'alerts',
                                    element: <LoadComponent component={Alerts} />,
                                },
                                {
                                    path: 'avatars',
                                    element: <LoadComponent component={Avatars} />,
                                },
                                {
                                    path: 'badges',
                                    element: <LoadComponent component={Badges} />,
                                },
                                {
                                    path: 'breadcrumb',
                                    element: <LoadComponent component={Breadcrumbs} />,
                                },
                                {
                                    path: 'buttons',
                                    element: <LoadComponent component={Buttons} />,
                                },
                                {
                                    path: 'cards',
                                    element: <LoadComponent component={Cards} />,
                                },
                                {
                                    path: 'carousel',
                                    element: <LoadComponent component={Carousels} />,
                                },
                                {
                                    path: 'dropdowns',
                                    element: <LoadComponent component={Dropdowns} />,
                                },
                                {
                                    path: 'embedvideo',
                                    element: <LoadComponent component={EmbedVideo} />,
                                },
                                {
                                    path: 'grid',
                                    element: <LoadComponent component={Grid} />,
                                },
                                {
                                    path: 'listgroups',
                                    element: <LoadComponent component={ListGroups} />,
                                },
                                {
                                    path: 'modals',
                                    element: <LoadComponent component={Modals} />,
                                },
                                {
                                    path: 'notifications',
                                    element: <LoadComponent component={Notifications} />,
                                },
                                {
                                    path: 'offcanvas',
                                    element: <LoadComponent component={Offcanvases} />,
                                },
                                {
                                    path: 'placeholders',
                                    element: <LoadComponent component={Placeholders} />,
                                },
                                {
                                    path: 'paginations',
                                    element: <LoadComponent component={Paginations} />,
                                },
                                {
                                    path: 'popovers',
                                    element: <LoadComponent component={Popovers} />,
                                },
                                {
                                    path: 'progress',
                                    element: <LoadComponent component={Progress} />,
                                },
                                {
                                    path: 'ribbons',
                                    element: <LoadComponent component={Ribbons} />,
                                },
                                {
                                    path: 'spinners',
                                    element: <LoadComponent component={Spinners} />,
                                },
                                {
                                    path: 'tabs',
                                    element: <LoadComponent component={Tabs} />,
                                },
                                {
                                    path: 'tooltips',
                                    element: <LoadComponent component={Tooltips} />,
                                },
                                {
                                    path: 'typography',
                                    element: <LoadComponent component={Typography} />,
                                },
                            ],
                        },
                        {
                            path: 'widgets',
                            element: <LoadComponent component={Widgets} />,
                        },
                        {
                            path: 'extended',
                            children: [
                                {
                                    path: 'dragdrop',
                                    element: <LoadComponent component={DragDrop} />,
                                },
                                {
                                    path: 'rangesliders',
                                    element: <LoadComponent component={RangeSliders} />,
                                },
                                {
                                    path: 'ratings',
                                    element: <LoadComponent component={Ratings} />,
                                },
                            ],
                        },
                        {
                            path: 'icons',
                            children: [
                                {
                                    path: 'unicons',
                                    element: <LoadComponent component={Unicons} />,
                                },
                                {
                                    path: 'mdi',
                                    element: <LoadComponent component={MDIIcons} />,
                                },
                                {
                                    path: 'dripicons',
                                    element: <LoadComponent component={Dripicons} />,
                                },
                            ],
                        },
                        {
                            path: 'forms',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicForms} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={FormAdvanced} />,
                                },
                                {
                                    path: 'validation',
                                    element: <LoadComponent component={FormValidation} />,
                                },
                                {
                                    path: 'wizard',
                                    element: <LoadComponent component={FormWizard} />,
                                },
                                {
                                    path: 'upload',
                                    element: <LoadComponent component={FileUpload} />,
                                },
                                {
                                    path: 'editors',
                                    element: <LoadComponent component={Editors} />,
                                },
                            ],
                        },
                        {
                            path: 'tables',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicTables} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={AdvancedTables} />,
                                },
                            ],
                        },
                        {
                            path: 'charts',
                            children: [
                                {
                                    path: 'apex',
                                    element: <LoadComponent component={ApexChart} />,
                                },
                                {
                                    path: 'chartjs',
                                    element: <LoadComponent component={ChartJs} />,
                                },
                            ],
                        },
                        {
                            path: 'maps',
                            children: [
                                {
                                    path: 'googlemaps',
                                    element: <LoadComponent component={GoogleMaps} />,
                                },
                                {
                                    path: 'vectormaps',
                                    element: <LoadComponent component={VectorMaps} />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
