import { ENTERPRISE } from 'helpers/api/enterpriseConst';
import { GetQuantidadeTarefasAtrasadasAgendadas } from 'webservice/acompanhamento';

export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
    notification?: {
        variantNotify: string;
        textNotify: string;
    };
};

function retornaMenuAplicativo(): MenuItemType[] {
    let newMenu: MenuItemType[];

    const produtoLogado: string = ENTERPRISE.pubProduto();
    const menuAtividadesCRM: MenuItemType = {
        key: 'crm-atividades',
        label: 'Central de Tarefas',
        isTitle: false,
        icon: produtoLogado === 'GESTAO' ? '' : 'uil-bell',
        url: '/apps/crm/atividades',
        notification: { textNotify: '', variantNotify: '' },
    };

    const menuProspectCRM: MenuItemType = {
        key: 'crm-pipeline-prospects',
        label: 'Prospects',
        url: '/apps/crm/pipeline/prospects',
        parentKey: 'apps-crm',
    };

    const menuLeadsCRM: MenuItemType = {
        key: 'crm-pipeline-leads',
        label: 'Leads',
        url: '/apps/crm/pipeline/leads',
        parentKey: 'apps-crm',
    };

    const menuOportunidadesCRM: MenuItemType = {
        key: 'crm-pipeline-oportunidades',
        label: 'Oportunidades',
        url: '/apps/crm/pipeline/oportunidades',
        parentKey: 'apps-crm',
    };

    const menuPosVendasCRM: MenuItemType = {
        key: 'crm-pipeline-posvendas',
        label: 'Pós-Vendas',
        isTitle: false,
        icon: produtoLogado === 'GESTAO' ? '' : 'uil-gold',
        url: '/apps/crm/pipeline/posvendas',
    };

    const menuCadastrosCRM: MenuItemType = {
        key: 'crm-cadastros',
        label: 'Configurações',
        url: '/apps/crm/cadastros',
        parentKey: 'apps-crm',
    };

    const menuItemModuloCRMForCRM: MenuItemType = {
        key: 'apps-crm',

        label: produtoLogado === 'GESTAO' ? 'CRM' : 'Meus Negócios',
        isTitle: false,
        icon: produtoLogado === 'GESTAO' ? 'uil-tachometer-fast' : 'uil-suitcase',

        children: [menuProspectCRM, menuLeadsCRM, menuOportunidadesCRM],
    };

    const menuItemModuloCRMForGESTAO: MenuItemType = {
        key: 'apps-crm',

        label: produtoLogado === 'GESTAO' ? 'CRM' : 'Meus Negócios',
        isTitle: false,
        icon: produtoLogado === 'GESTAO' ? 'uil-tachometer-fast' : 'uil-suitcase',

        children: [
            menuAtividadesCRM,
            menuProspectCRM,
            menuLeadsCRM,
            menuOportunidadesCRM,
            menuPosVendasCRM,
            menuCadastrosCRM,
        ],
    };

    const funilVendasCRM = {
        key: 'crm-funilvendas',
        label: 'Funil de Vendas',
        url: '/dashboard/crm/funilvendas',
        parentKey: 'dashboard-crm',
    };
    const graficoVendasCRM = {
        key: 'crm-graficovendas',
        label: 'Vendas',
        url: '/dashboard/crm/graficovendas',
        parentKey: 'dashboard-crm',
    };
    const tarefasCRM = {
        key: 'crm-indicador',
        label: 'Tarefas',
        url: '/dashboard/crm/indicador',
        parentKey: 'dashboard-crm',
    };
    const graficosProspectCRM = {
        key: 'crm-graficoprospect',
        label: 'Prospects',
        url: '/dashboard/crm/graficoprospect',
        parentKey: 'dashboard-crm',
    };
    const graficosLeadsCRM = {
        key: 'crm-graficoleads',
        label: 'Leads',
        url: '/dashboard/crm/graficoleads',
        parentKey: 'dashboard-crm',
    };
    const graficosOportunidadesCRM = {
        key: 'crm-graficooportunidades',
        label: 'Oportunidades',
        url: '/dashboard/crm/graficooportunidades',
        parentKey: 'dashboard-crm',
    };

    const menuItemModuloCadastroConfiguracaoCRM: MenuItemType = {
        key: 'crm',
        label: 'Configurações',
        isTitle: false,
        icon: 'mdi mdi-cog',
        url: 'apps/crm/Cadastros/',
    };

    const menuItemVendasCRM: MenuItemType = {
        key: 'apps-vendas',
        label: 'Vendas',
        isTitle: false,
        icon: 'uil-clipboard-notes',
        url: '/apps/vendas/listagemvendas/listavendas',
    };

    const menuItemDashboardCRM: MenuItemType = {
        key: 'dashboard-crm',
        label: produtoLogado === 'GESTAO' ? 'CRM' : 'Meus Resultados',
        isTitle: false,
        icon: produtoLogado === 'GESTAO' ? 'uil-tachometer-fast' : 'uil-chart-line',
        children: [
            funilVendasCRM,
            graficoVendasCRM,
            tarefasCRM,
            graficosProspectCRM,
            graficosLeadsCRM,
            graficosOportunidadesCRM,
        ],
    };

    const menuItemUNCPix: MenuItemType = {
        key: 'unc-pix',
        label: 'imaisConta',
        isTitle: false,
        icon: 'uil-money-withdraw',
        children: [
            {
                key: 'abrir-conta',
                label: 'Abertura de Conta',
                url: 'apps/UNCPix/AbrirConta/',
                parentKey: 'unc-pix',
            },
            {
                key: 'extrato-saldo',
                label: 'Extrato/Saldo',
                url: 'apps/UNCPix/ExtratoSaldo/',
                parentKey: 'unc-pix',
            },
            {
                key: 'conta-transferencia',
                label: 'Conta de Transferência',
                url: 'apps/UNCPix/ContaTransferencia/',
                parentKey: 'unc-pix',
            },
        ],
    };

    const gestaoMenu: MenuItemType[] = [
        { key: 'gestao', label: 'Gestão', isTitle: true },
        {
            key: 'dashboard-vendas',
            label: 'Vendas',
            isTitle: false,
            icon: 'uil-clipboard-notes',
            children: [
                {
                    key: 'vendas-dashboard',
                    label: 'Indicadores',
                    url: '/dashboard/vendas/indicador',
                    parentKey: 'dashboard-vendas',
                },
                {
                    key: 'vendas-analise-comparativa',
                    label: 'Analises Comparativas',
                    url: '/dashboard/vendas/analisecomparativa',
                    parentKey: 'dashboard-vendas',
                },
            ],
        },

        {
            key: 'dashboard-financeiro',
            label: 'Financeiro',
            isTitle: false,
            icon: 'uil-wallet',
            children: [
                {
                    key: 'financeiro-indicador',
                    label: 'Indicadores',
                    url: '/dashboard/financeiro/indicador',
                    parentKey: 'dashboard-financeiro',
                },
                {
                    key: 'financeiro-analise-comparativa',
                    label: 'Analises Comparativas',
                    url: '/dashboard/financeiro/analisecomparativa',
                    parentKey: 'dashboard-financeiro',
                },
            ],
        },

        {
            key: 'dashboard-compras',
            label: 'Compras',
            isTitle: false,
            icon: 'uil-bill',
            children: [
                {
                    key: 'compras-indicador-solicitacao',
                    label: 'Ind. - Solicitações',
                    url: '/dashboard/compras/indicadorsolicitacao',
                    parentKey: 'dashboard-compras',
                },
                {
                    key: 'compras-indicador-cotacao',
                    label: 'Ind. - Cotações',
                    url: '/dashboard/compras/indicadorcotacao',
                    parentKey: 'dashboard-compras',
                },
                {
                    key: 'compras-indicador-pedidocompra',
                    label: 'Ind. - Pedidos',
                    url: '/dashboard/compras/indicadorpedido',
                    parentKey: 'dashboard-compras',
                },
            ],
        },
        {
            key: 'dashboard-producao',
            label: 'Produção',
            isTitle: false,
            icon: 'uil-presentation-check',
            children: [
                {
                    key: 'producao-indicador',
                    label: 'Indicadores',
                    url: '/dashboard/producao/indicador',
                    parentKey: 'dashboard-producao',
                },
            ],
        },
        {
            key: 'dashboard-estoque',
            label: 'Estoque',
            isTitle: false,
            icon: 'uil-archive',
            children: [
                {
                    key: 'estoque-indicador',
                    label: 'Indicadores',
                    url: '/dashboard/estoque/indicador',
                    parentKey: 'dashboard-estoque',
                },
            ],
        },

        menuItemDashboardCRM,

        { key: 'modulos', label: 'Módulos', isTitle: true },

        {
            key: 'apps-vendas',
            label: 'Vendas',
            isTitle: false,
            icon: 'uil-clipboard-notes',
            children: [
                {
                    key: 'vendas-listagem',
                    label: 'Orçamento/Venda',
                    url: '/apps/vendas/listagemvendas/listavendas',
                    parentKey: 'apps-vendas',
                },
                {
                    key: 'vendas-listagem',
                    label: 'Incluir Venda',
                    url: '/apps/vendas/cadastrovendas/cadastravendas',
                    parentKey: 'apps-vendas',
                },
            ],
        },

        menuItemModuloCRMForGESTAO,
        menuItemUNCPix,
    ];

    const crmMenu: MenuItemType[] = [
        //{ key: 'modulos', label: 'CRM', isTitle: true },
        menuAtividadesCRM,
        menuItemModuloCRMForCRM,
        menuPosVendasCRM,
        menuItemDashboardCRM,
        menuItemVendasCRM,
        menuItemModuloCadastroConfiguracaoCRM,
    ];

    if (produtoLogado === 'GESTAO') {
        newMenu = gestaoMenu;
    } else {
        newMenu = crmMenu;
    }

    return newMenu;
}

const MENU_ITEMS: MenuItemType[] = retornaMenuAplicativo();

export { MENU_ITEMS };
