function createOptionsSelected(codigo: string | number, descricao: string, addValueInLabel: boolean = false): any {
    if (codigo !== undefined && codigo !== '') {
        if (addValueInLabel) {
            const value = {
                value: codigo,
                label: `${codigo} - ${descricao}`,
            };
            return value;
        } else {
            const value = {
                value: codigo,
                label: descricao,
            };
            return value;
        }
    } else {
        const value = '';
        return value;
    }
}

export { createOptionsSelected };
