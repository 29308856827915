/* Layout types and other constants */

export enum LayoutTypes {
    LAYOUT_VERTICAL = 'vertical',
    LAYOUT_HORIZONTAL = 'topnav',
    LAYOUT_DETACHED = 'detached',
    LAYOUT_FULL = 'full',
    layout = 'layout',
}

export enum LayoutColor {
    LAYOUT_COLOR_LIGHT = 'light',
    LAYOUT_COLOR_DARK = 'dark',
}

export enum LayoutWidth {
    LAYOUT_WIDTH_FLUID = 'fluid',
    LAYOUT_WIDTH_BOXED = 'boxed',
}

export enum SideBarTheme {
    LEFT_SIDEBAR_THEME_DEFAULT = 'default',
    LEFT_SIDEBAR_THEME_LIGHT = 'light',
    LEFT_SIDEBAR_THEME_DARK = 'dark',
    LEFT_SIDEBAR_THEME_TECTUM = 'tectum',
    LEFT_SIDEBAR_THEME_EMPRESARIAL = 'empresarial',
    LEFT_SIDEBAR_THEME_SPLIT = 'split',
    LEFT_SIDEBAR_THEME_INDUSTRIALMAIS = 'industrialmais',
}

export enum SideBarWidth {
    LEFT_SIDEBAR_TYPE_FIXED = 'fixed',
    LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed',
    LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable',
}
