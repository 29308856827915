import Routes from 'routes/Routes';
import { entrar, logout } from 'helpers';
import 'rsuite/dist/rsuite.css';
import 'assets/scss/Saas.scss';
import 'toastr/build/toastr.min.css';

const App = () => {
    entrar();

    return <Routes />;
};

export default App;
